import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import HomeNav from './HomeNav';
import Footer from './Components/Footer/Footer';
import BannerSection from './Components/BannerSection';
import Background, { BackgroundElement } from './Components/Background';
import EmailSection from './Components/EmailSection';
import RoundedButton from '../../Components/RoundedButton/RoundedButton';
import { OpenDemoLink } from '../../Components/Utilities/HelperFunctions';
import {
  XpektorForm,
  FormInput,
  FormTextArea,
  FormButton,
} from '../../Components/XpektorForms/XpektorForms';

// Media
import SwirlyLines1 from '../../Images/Home/swirly_lines_1.png';
import SwirlyLines2 from '../../Images/Home/swirly_lines_2.png';
import LocationImage from '../../Images/Home/office_location.png';

export default function About() {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const history = useHistory();

  const sendMessage = (e) => {
    e.preventDefault();

    window.open(`mailto:info@xpektor.se?subject=${subject}&body=${message}`);
  };

  return (
    <div className="xpektor-home">
      <div className="other-intro about-intro">
        <HomeNav />
        <div className="section intro-section">
          <p className="section-title">Om oss</p>
          <h1>
            Xpektor revolutionerar
            <br /> Er beslutsprocess
          </h1>
          <div className="button-row">
            <RoundedButton
              text="Läs mer"
              fillCyan
              onClick={() => {
                history.push('/why');
              }}
            />
            <RoundedButton text="Boka demo" onClick={OpenDemoLink} />
          </div>
        </div>
      </div>
      <Background color1="#000B4F" color2="#010024">
        <div className="section-with-element">
          <div className="section founder-section">
            <p className="section-title">XPEKTOR</p>
            <h2>
              Xpektor grundades med visionen att möjliggöra bättre
              affärskritiska beslut för både kreditmarknaden och
              rådgivningsbranschen.
            </h2>
            <div className="founder-content">
              <p className="section-text">
                Xpektors grundare, Hanna Gårdamark, Daniel Eremar och Petter
                Eriksson utvecklade Xpektor genom deras expertis inom
                företagsekonomi och cybersäkerhet. Avgörande kunskap inom
                affärssystem, finansiella marknader och mjukvaruutveckling
                gjorde att de tidigt kunde identifiera behovet av att kombinera
                datapunkter från affärssystem och banktransaktioner. Xpektor
                kunde genom det skapa en heltäckande analys av ett företag.
                <br />
                <br />
                Resultatet blev Xpektor – en plattform som levererar en
                heltäckande analys av ett företags framtida situation med hjälp
                av realtidsdata. Med Xpektor kan kreditmarknaden och
                rådgivningsbranschen fatta precisa finansiella beslut och minska
                risken för penningtvätt och bedrägeri.
              </p>
            </div>
          </div>
          <BackgroundElement img={SwirlyLines1} yPos={-200} />
        </div>
        <div className="location-section">
          <div className="text-column">
            <h3>Hitta till oss</h3>
            <p className="section-text">
              Vi befinner oss i Göteborg på Hvitfeldtsplatsen 7.
            </p>
          </div>
          <img src={LocationImage} alt="location_image" />
        </div>
        <div className="section-with-element">
          <div className="section">
            <div className="message-section">
              <h3>Hör av dig till oss så berättar vi mer.</h3>
              <XpektorForm onSubmit={sendMessage}>
                <FormInput
                  label="Rubrik"
                  type="text"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
                <FormTextArea
                  label="Meddelande"
                  rows={6}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <FormButton text="Skicka meddelande" />
              </XpektorForm>
            </div>
          </div>
          <BackgroundElement img={SwirlyLines2} yPos={-200} />
        </div>
        <div className="section">
          <EmailSection />
        </div>
      </Background>
      <Background end>
        <BannerSection />
        <Footer darkMode />
      </Background>
    </div>
  );
}
