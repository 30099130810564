import './Why.scss';
import Timer from '../../../Images/Why/timer.png';
import Handshake from '../../../Images/Why/handshake.png';
import SKVIcon from '../../../Images/Why/SKV_icon.png';
import AccountingIcon from '../../../Images/Why/Accounting_icon.png';
import BankIcon from '../../../Images/Why/Bank_icon.png';
import HomeNav from '../HomeNav';
import Background, { BackgroundElement } from '../Components/Background';
import Footer from '../Components/Footer/Footer';

// Media
import SwirlyLines1 from '../../../Images/Home/swirly_lines_1.png';

export default function Why() {
  return (
    <div className="xpektor-home">
      <div className="other-intro why-intro">
        <HomeNav />
        <div className="section intro-section">
          <h1>Varför Xpektor</h1>
          <p className="section-text">
            För att förstå vart ett företag befinner sig och vart det är på väg
            så gäller det att ha koll på ett antal saker samtidigt, dessutom i
            realtid. Det kan låta krångligt men med Xpektor blir det enkelt.
            <br />
            <br />
            Ett stort problem idag är att siffror enkelt kan manipuleras, något
            som också slinker igenom i kreditbedömningar och analyser. Xpektor
            ändrar på det.
          </p>
        </div>
      </div>
      <Background color1="#000B4F" color2="#010024">
        <div className="section-with-element">
          <div className="section">
            <div className="why-row">
              <div className="why-row-text-column">
                <h2>
                  Realtidsinformation från flera datakällor viktigare än
                  någonsin
                </h2>
                <p className="section-text">
                  Det finns inte en enskild datakälla som gör det möjligt att
                  validera ett företag utan för att lyckas krävs det att man
                  korsmäter och samkör ett antal källor samtidigt. För att ta
                  ett exempel: Vad händer om man enkom tittar på ett företags
                  bokföring eller banktransaktioner? Hur vet man då om allt är i
                  ordning och hur hittar man avvikelser som gömmer sig? Hur är
                  det möjligt att veta om företaget mottagit mer pengar än vad
                  som fakturerats eller vice versa? På vilket sätt skapas en
                  fullständig bild enbart via bokföring eller bank? Det går helt
                  enkelt inte och därför finns Xpektor.
                </p>
              </div>
              <img src={Timer} alt="Timer" className="why-row-image" />
            </div>
          </div>
          <BackgroundElement img={SwirlyLines1} yPos={-100} />
        </div>
        <div className="section">
          <div className="why-row">
            <img src={Handshake} alt="Handshake" className="why-row-image" />
            <div className="why-row-text-column">
              <h2>Med Xpektor sparar alla tid</h2>
              <p className="section-text">
                Xpektor visar allt ni behöver för att fatta välgrundade beslut
                och med hjälp av vårt score, analyser och notiser får ni ett
                kraftfullt stöd. På så sätt sparas värdefull tid som annars
                läggs på manuellt arbete.
              </p>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="why-connections-row">
            <div className="why-connection-column">
              <img
                src={AccountingIcon}
                alt="Accounting"
                className="why-column-image"
              />
              <h2>Varför bokföring</h2>
              <p className="section-text">
                Genom att koppla sin bokföring till Xpektor skapas vad som
                liknar en dagsfärsk årsredovisning. Därmed får handläggaren en
                klar bild över företagets ekonomiska situation. Xpektor läser
                <b>
                  <i> inte </i>
                </b>
                bokföring på transaktionsnivå och Xpektor ser
                <b>
                  <i> inte </i>
                </b>
                varje enskild transaktion, något som kan vara känsligt. Istället
                summerar Xpektor helheten och kan utläsa väsentlig information
                utifrån det.
              </p>
            </div>
            <div className="why-connection-column">
              <img
                src={BankIcon}
                alt="Accounting"
                className="why-column-image"
              />
              <h2>Varför företagskonto</h2>
              <p className="section-text">
                Att få förståelse för vad som händer på företagskontot är
                viktigt. Betalas pengar till högriskländer? Sker betalningar
                till kronofogden? Detta är bara exempel på frågor som långivaren
                vill och behöver förstå innan en affärsmässig förbindelse kan
                inledas.
              </p>
            </div>
            <div className="why-connection-column">
              <img
                src={SKVIcon}
                alt="Accounting"
                className="why-column-image"
              />
              <h2>Varför Skatteverket</h2>
              <p className="section-text">
                Ett allt vanligare sätt att utföra ekonomisk brottslighet är via
                skattekontot (Skatteverket) varför en långivare vill få en
                inblick. Det är också viktigt att säkerställa att den som söker
                lån eller kredit är registrerad på ett korrekt sätt. Exempelvis
                för F-skatt och i arbetsgivarregistret.
              </p>
            </div>
          </div>
        </div>
        <Footer darkMode />
      </Background>
    </div>
  );
}
