/* eslint-disable react/prop-types */
import { useState, useEffect, useRef } from 'react';
import Moment from 'moment';
import generatePDF from 'react-to-pdf';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import Report from '../../../../Components/Report/Report';
import Module from './Module';
import AccountService from '../../../../Services/AccountService';
import XpektorButton from '../../../../Components/XpektorButton/XpektorButton';
import LoadingBar from '../../../../Components/LoadingBar/LoadingBar';

export default function Reports({ client }) {
  const [fiscalYear, setFiscalYear] = useState('');
  const [reportPeriod, setReportPeriod] = useState('');

  const [accountReport, setAccountReport] = useState([]);
  const [fetchingAccountData, setFetchingAccountData] = useState(false);
  const targetRef = useRef();
  const [verticalMode, setVerticalMode] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setFetchingAccountData(true);

      await AccountService.getAccountReport(client.id)
        .then((response) => {
          setAccountReport(response.data);

          const fromDate = `${Moment(response.data.fromDate).format(
            'YYYY-MM-DD'
          )}`;
          const toDate = `${Moment(response.data.toDate).format('YYYY-MM-DD')}`;
          const currentDate = Moment.utc().local();

          setFiscalYear(`${fromDate} - ${toDate}`);
          setReportPeriod(
            `${fromDate} - ${Moment(currentDate).format('YYYY-MM-DD')}`
          );
        })
        .catch(() => {})
        .finally(() => {
          setFetchingAccountData(false);
        });
    };
    if (client.externalConnectionActive) {
      getData();
    }
  }, [client]);

  function BalanceAccountsSum(accounts) {
    let balanceIn = 0.0;
    let balanceOut = 0.0;
    let period = 0.0;

    for (let i = 0; i < accounts.length; i += 1) {
      balanceIn += accounts[i].balanceBroughtForward;
      balanceOut += accounts[i].balanceCarriedForward;
      period +=
        accounts[i].balanceCarriedForward - accounts[i].balanceBroughtForward;
    }
    return [balanceIn, balanceIn, period, balanceOut];
  }

  function BalanceAccountListsSum(accountLists) {
    let balanceIn = 0.0;
    let balanceOut = 0.0;
    let period = 0.0;

    for (let i = 0; i < accountLists.length; i += 1) {
      const accounts = accountLists[i];
      for (let j = 0; j < accounts.length; j += 1) {
        balanceIn += accounts[j].balanceBroughtForward;
        balanceOut += accounts[j].balanceCarriedForward;
        period +=
          accounts[j].balanceCarriedForward - accounts[j].balanceBroughtForward;
      }
    }
    return [balanceIn, balanceIn, period, balanceOut];
  }

  function ResultAccountsSum(accounts) {
    let period = 0.0;
    let previousPeriod = 0.0;

    for (let i = 0; i < accounts.length; i += 1) {
      period +=
        accounts[i].balanceCarriedForward - accounts[i].balanceBroughtForward;
      previousPeriod += accounts[i].previousPeriod;
    }
    return [period, period, previousPeriod];
  }

  function AddAccountsSums(sumArray, sumArrayToAdd) {
    const newSum = [];
    for (let i = 0; i < sumArray.length || i < sumArrayToAdd.length; i += 1) {
      newSum.push(sumArray[i] + sumArrayToAdd[i]);
    }
    return newSum;
  }

  function SubtractAccountsSums(sumArray, sumArrayToSubtract) {
    const newSum = [];
    for (
      let i = 0;
      i < sumArray.length || i < sumArrayToSubtract.length;
      i += 1
    ) {
      newSum.push(sumArray[i] - sumArrayToSubtract[i]);
    }
    return newSum;
  }

  function ResultAccountListsSum(accountLists) {
    let period = 0.0;
    let previousPeriod = 0.0;

    for (let i = 0; i < accountLists.length; i += 1) {
      const accounts = accountLists[i];
      for (let j = 0; j < accounts.length; j += 1) {
        period +=
          accounts[j].balanceCarriedForward - accounts[j].balanceBroughtForward;
        previousPeriod += accounts[j].previousPeriod;
      }
    }
    return [period, period, previousPeriod];
  }

  function MapBalanceAccounts(accounts) {
    return accounts.map((account) => {
      return (
        <Report.TableRow
          number={account.id}
          title={account.description}
          values={[
            account.balanceBroughtForward,
            account.balanceBroughtForward,
            account.balanceCarriedForward - account.balanceBroughtForward,
            account.balanceCarriedForward,
          ]}
          key={account.id}
        />
      );
    });
  }

  function MapResultAccounts(accounts) {
    return accounts.map((account) => {
      return (
        <Report.TableRow
          number={account.id}
          title={account.description}
          values={[
            account.balanceCarriedForward - account.balanceBroughtForward,
            account.balanceCarriedForward - account.balanceBroughtForward,
            account.previousPeriod,
          ]}
          key={account.id}
        />
      );
    });
  }

  // BALANCE ACCOUNT SUMS
  let fixedAssetsSum = [];
  let revenueAssetsSum = [];
  let assetsSum = [];
  let equityProvisionsAndDebtSum = [];
  let balanceResultSum = [];

  // RESULT ACCOUNT SUMS
  let operatingRevenueSum = [];
  let grossProfitSum = [];
  let operatingIncomeSum = [];
  let resultAfterFinancialPostsSum = [];
  let operatingExpensesSum = [];
  let calculatedResultSum = [];

  if (Object.keys(accountReport).length !== 0) {
    fixedAssetsSum = BalanceAccountListsSum([
      accountReport.immaterialFixedAssets,
      accountReport.materialFixedAssets,
      accountReport.financialFixedAssets,
    ]);
    revenueAssetsSum = BalanceAccountListsSum([
      accountReport.inventory,
      accountReport.shortTermReceivables,
      accountReport.cashAndBank,
    ]);

    assetsSum = AddAccountsSums(fixedAssetsSum, revenueAssetsSum);

    equityProvisionsAndDebtSum = BalanceAccountListsSum([
      accountReport.equity,
      accountReport.untaxedReserves,
      accountReport.provisions,
      accountReport.longTermDebt,
      accountReport.shortTermDebt,
    ]);

    balanceResultSum = AddAccountsSums(assetsSum, equityProvisionsAndDebtSum);

    operatingRevenueSum = ResultAccountListsSum([
      accountReport.netRevenue,
      accountReport.otherOperatingRevenue,
    ]);
    grossProfitSum = AddAccountsSums(
      operatingRevenueSum,
      ResultAccountsSum(accountReport.materialsAndConsumables)
    );
    operatingIncomeSum = AddAccountsSums(
      grossProfitSum,
      ResultAccountListsSum([
        accountReport.otherExternalCosts,
        accountReport.personellCosts,
        accountReport.depreciations,
        accountReport.otherOperatingCosts,
      ])
    );
    resultAfterFinancialPostsSum = AddAccountsSums(
      operatingIncomeSum,
      ResultAccountsSum(accountReport.financialPosts)
    );
    operatingExpensesSum = AddAccountsSums(
      SubtractAccountsSums(resultAfterFinancialPostsSum, operatingRevenueSum),
      ResultAccountListsSum([
        accountReport.financialStatements,
        accountReport.tax,
      ])
    );
    calculatedResultSum = AddAccountsSums(
      operatingExpensesSum,
      operatingRevenueSum
    );
  }

  const renderReports = () => (
    <div ref={targetRef}>
      <Module.ReportSection vertical={verticalMode}>
        <Report
          title="Balansrapport"
          client={client}
          fiscalYear={fiscalYear}
          period={reportPeriod}
          dateCreated={accountReport.dateCreated}
        >
          <Report.TableRow
            title=""
            values={['Ing balans', 'Ing saldo', 'Period', 'Utg balans']}
            type="text"
          />
          <Report.Divider />
          <Report.TableRow title="TILLGÅNGAR" bold />
          <Report.TableRow title="Anläggningstillgångar" bold />
          <Report.TableRow title="Immateriella anläggningstillgångar" />
          <Report.ListSection>
            {MapBalanceAccounts(accountReport.immaterialFixedAssets)}
          </Report.ListSection>
          <Report.TableRow
            title="Summa immateriella anläggningstillgångar"
            values={BalanceAccountsSum(accountReport.immaterialFixedAssets)}
            marginBottom
          />
          <Report.TableRow title="Materiella anläggningstillgångar" />
          <Report.ListSection>
            {MapBalanceAccounts(accountReport.materialFixedAssets)}
          </Report.ListSection>
          <Report.TableRow
            title="Summa materiella anläggningstillgångar"
            values={BalanceAccountsSum(accountReport.materialFixedAssets)}
            marginBottom
          />
          <Report.TableRow title="Finansiella anläggningstillgångar" />
          <Report.ListSection>
            {MapBalanceAccounts(accountReport.financialFixedAssets)}
          </Report.ListSection>
          <Report.TableRow
            title="Summa finansiella anläggningstillgångar"
            values={BalanceAccountsSum(accountReport.financialFixedAssets)}
            marginBottom
          />
          <Report.TableRow
            title="Summa anläggningstillgångar"
            values={fixedAssetsSum}
            bold
            marginBottom
          />
          <Report.TableRow title="Omsättningstillgångar" bold />
          <Report.TableRow title="Varulager m.m." />
          <Report.ListSection>
            {MapBalanceAccounts(accountReport.inventory)}
          </Report.ListSection>
          <Report.TableRow
            title="Summa varulager m.m."
            values={BalanceAccountsSum(accountReport.inventory)}
            marginBottom
          />
          <Report.TableRow title="Kortfristiga fordringar" />
          <Report.ListSection>
            {MapBalanceAccounts(accountReport.shortTermReceivables)}
          </Report.ListSection>
          <Report.TableRow
            title="Summa kortfristiga fordringar"
            values={BalanceAccountsSum(accountReport.shortTermReceivables)}
            marginBottom
          />
          <Report.TableRow title="Kassa och bank" />
          <Report.ListSection>
            {MapBalanceAccounts(accountReport.cashAndBank)}
          </Report.ListSection>
          <Report.TableRow
            title="Summa kassa och bank"
            values={BalanceAccountsSum(accountReport.cashAndBank)}
            marginBottom
          />
          <Report.TableRow
            title="Summa omsättningstillgångar"
            values={revenueAssetsSum}
            marginBottom
            bold
          />
          <Report.TableRow
            title="SUMMA TILLGÅNGAR"
            values={assetsSum}
            bold
            marginBottom
          />
          <Report.TableRow
            title="EGET KAPITAL, AVSÄTTNINGAR, OCH SKULDER"
            bold
          />
          <Report.TableRow title="Eget kapital" />
          <Report.ListSection>
            {MapBalanceAccounts(accountReport.equity)}
          </Report.ListSection>
          <Report.TableRow
            title="Summa eget kapital"
            values={BalanceAccountsSum(accountReport.equity)}
            marginBottom
          />
          <Report.TableRow title="Obeskattade reserver" />
          <Report.ListSection>
            {MapBalanceAccounts(accountReport.untaxedReserves)}
          </Report.ListSection>
          <Report.TableRow
            title="Summa obeskattade reserver"
            values={BalanceAccountsSum(accountReport.untaxedReserves)}
            marginBottom
          />
          <Report.TableRow title="Avsättningar" />
          <Report.ListSection>
            {MapBalanceAccounts(accountReport.provisions)}
          </Report.ListSection>
          <Report.TableRow
            title="Summa avsättningar"
            values={BalanceAccountsSum(accountReport.provisions)}
            marginBottom
          />
          <Report.TableRow title="Långfristiga skulder" />
          <Report.ListSection>
            {MapBalanceAccounts(accountReport.longTermDebt)}
          </Report.ListSection>
          <Report.TableRow
            title="Summa långfristiga skulder"
            values={BalanceAccountsSum(accountReport.longTermDebt)}
            marginBottom
          />
          <Report.TableRow title="Kortfristiga skulder" />
          <Report.ListSection>
            {MapBalanceAccounts(accountReport.shortTermDebt)}
          </Report.ListSection>
          <Report.TableRow
            title="Summa kortfristiga skulder"
            values={BalanceAccountsSum(accountReport.shortTermDebt)}
            marginBottom
          />
          <Report.TableRow
            title="SUMMA EGET KAPITAL, AVSÄTTNINGAR, OCH SKULDER"
            values={equityProvisionsAndDebtSum}
            marginBottom
            bold
          />
          <Report.TableRow
            title="BERÄKNAT RESULTAT"
            values={balanceResultSum}
            bold
          />
        </Report>
        <Report
          title="Resultatrapport"
          client={client}
          fiscalYear={fiscalYear}
          period={reportPeriod}
          dateCreated={accountReport.dateCreated}
        >
          <Report.TableRow
            title=""
            values={['Period', 'Ackumulerat', 'Totalt föregående år']}
            type="text"
          />
          <Report.Divider />
          <Report.TableRow title="RÖRELSENS INTÄKTER" bold />
          <Report.TableRow title="Nettoomsättning" bold />
          <Report.ListSection>
            {MapResultAccounts(accountReport.netRevenue)}
          </Report.ListSection>
          <Report.TableRow
            title="Summa nettoomsättning"
            values={ResultAccountsSum(accountReport.netRevenue)}
            marginBottom
            bold
          />
          <Report.TableRow title="Övriga rörelseintäkter" bold />
          <Report.ListSection>
            {MapResultAccounts(accountReport.otherOperatingRevenue)}
          </Report.ListSection>
          <Report.TableRow
            title="Summa övriga rörelseintäkter"
            values={ResultAccountsSum(accountReport.otherOperatingRevenue)}
            marginBottom
            bold
          />
          <Report.TableRow
            title="SUMMA RÖRELSENS INTÄKTER"
            values={operatingRevenueSum}
            marginBottom
            bold
          />
          <Report.TableRow title="RÖRELSENS KOSTNADER" bold />
          <Report.TableRow title="Råvaror och förnödenheter" bold />
          <Report.ListSection>
            {MapResultAccounts(accountReport.materialsAndConsumables)}
          </Report.ListSection>
          <Report.TableRow
            title="Summa råvaror och förnödenheter"
            values={ResultAccountsSum(accountReport.materialsAndConsumables)}
            marginBottom
            bold
          />
          <Report.TableRow
            title="BRUTTOVINST"
            values={grossProfitSum}
            marginBottom
            bold
          />
          <Report.TableRow title="Övriga externa kostnader" bold />
          <Report.ListSection>
            {MapResultAccounts(accountReport.otherExternalCosts)}
          </Report.ListSection>
          <Report.TableRow
            title="Summa övriga externa kostnader"
            values={ResultAccountsSum(accountReport.otherExternalCosts)}
            marginBottom
            bold
          />
          <Report.TableRow title="Personalkostnader" bold />
          <Report.ListSection>
            {MapResultAccounts(accountReport.personellCosts)}
          </Report.ListSection>
          <Report.TableRow
            title="Summa personalkostnader"
            values={ResultAccountsSum(accountReport.personellCosts)}
            marginBottom
            bold
          />
          <Report.TableRow title="Avskrivningar och nedskrivningar" bold />
          <Report.ListSection>
            {MapResultAccounts(accountReport.depreciations)}
          </Report.ListSection>
          <Report.TableRow
            title="Summa avskrivningar"
            values={ResultAccountsSum(accountReport.depreciations)}
            marginBottom
            bold
          />
          <Report.TableRow title="Övriga rörelsekostnader" bold />
          <Report.ListSection>
            {MapResultAccounts(accountReport.otherOperatingCosts)}
          </Report.ListSection>
          <Report.TableRow
            title="Summa övriga rörelsekostnader"
            values={ResultAccountsSum(accountReport.otherOperatingCosts)}
            marginBottom
            bold
          />
          <Report.TableRow
            title="RÖRELSERESULTAT"
            values={operatingIncomeSum}
            bold
          />
          <Report.TableRow title="Finansiella poster" bold />
          <Report.ListSection>
            {MapResultAccounts(accountReport.financialPosts)}
          </Report.ListSection>
          <Report.TableRow
            title="Summa finansiella poster"
            values={ResultAccountsSum(accountReport.financialPosts)}
            marginBottom
            bold
          />
          <Report.TableRow
            title="RESULTAT EFTER FINANSIELLA POSTER"
            values={resultAfterFinancialPostsSum}
            bold
          />
          <Report.TableRow title="Bokslutsdispositioner" bold />
          <Report.ListSection>
            {MapResultAccounts(accountReport.financialStatements)}
          </Report.ListSection>
          <Report.TableRow
            title="Summa bokslutsdispositioner"
            values={ResultAccountsSum(accountReport.financialStatements)}
            marginBottom
            bold
          />
          <Report.TableRow title="Skatt" bold />
          <Report.ListSection>
            {MapResultAccounts(accountReport.tax)}
          </Report.ListSection>
          <Report.TableRow
            title="Summa skatt"
            values={ResultAccountsSum(accountReport.tax)}
            marginBottom
            bold
          />
          <Report.TableRow
            title="SUMMA RÖRELSENS KOSTNADER"
            values={operatingExpensesSum}
            marginBottom
            bold
          />
          <Report.TableRow title="Årets resultat" bold />
          <Report.ListSection>
            {MapResultAccounts(accountReport.yearsResults)}
          </Report.ListSection>
          <Report.TableRow
            title="Summa årets resultat"
            values={ResultAccountsSum(accountReport.yearsResults)}
            marginBottom
            bold
          />
          <Report.TableRow
            title="BERÄKNAT RESULTAT"
            values={calculatedResultSum}
            bold
          />
        </Report>
      </Module.ReportSection>
    </div>
  );

  return (
    <Module>
      {!client.externalConnectionActive ? (
        <p className="module-empty-text">Inget bokföringssystem kopplat.</p>
      ) : fetchingAccountData ? (
        <LoadingBar active status="Hämtar data..." relative />
      ) : Object.keys(accountReport).length === 0 ? (
        <p className="module-empty-text">Finns ingen data.</p>
      ) : (
        <>
          <Module.Nav>
            <div />
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
              <XpektorButton
                content={verticalMode ? 'Vertikalt' : 'Sida vid sida'}
                onClick={() => setVerticalMode(!verticalMode)}
              />
              <XpektorButton
                icon={faDownload}
                content="Spara som PDF"
                onClick={() =>
                  generatePDF(targetRef, {
                    filename: `Balans_och_resultat-${Moment.utc()
                      .local()
                      .format('YYYY-MM-DD')}-XPEKTOR.pdf`,
                  })
                }
              />
            </div>
          </Module.Nav>
          {renderReports()}
        </>
      )}
    </Module>
  );
}
