import { useHistory } from 'react-router-dom';
import HomeNav from './HomeNav';
import Footer from './Components/Footer/Footer';
import RoundedButton from '../../Components/RoundedButton/RoundedButton';
import HelpOfferSection from './Components/HelpOfferSection';
import SpiderWebSection from './Components/SpiderWebSection';
import BannerSection from './Components/BannerSection';
import Background, { BackgroundElement } from './Components/Background';
import { OpenDemoLink } from '../../Components/Utilities/HelperFunctions';

// Media
import SwirlyLines2 from '../../Images/Home/swirly_lines_2.png';
// import SwarmWave from '../../Images/Home/swarm_wave.png';
import HelpOffer2 from '../../Images/Home/help_offer_2.png';
import FinanceProduct from '../../Images/Home/product_score.png';
import FinanceWeb from '../../Images/Home/xpektor_web_finance.png';

export default function Finance() {
  const history = useHistory();

  return (
    <div className="xpektor-home">
      <div className="other-intro finance-intro">
        <HomeNav />
        <div className="section intro-section">
          <p className="section-title">Finance</p>
          <h1>
            Bank & <br />
            Kreditgivare
          </h1>
          <div className="button-row">
            <RoundedButton
              text="Läs mer"
              fillCyan
              onClick={() => {
                history.push('/why');
              }}
            />
            <RoundedButton text="Boka demo" onClick={OpenDemoLink} />
          </div>
        </div>
      </div>
      <Background color1="white">
        <div className="intro-row-section finance-intro-row">
          <h3>Bedöm risker och möjligheter i realtid</h3>
          <p className="section-text">
            <i>{`"Med hjälp av Xpektor kan vi nu se kundens bokföring och
            bankkontotransaktioner i realtid. Detta har hjälpt oss att skapa en
            realistisk bild av en potentiell kund och ta bättre kreditbeslut. 
            Xpektor har även hjälpt oss att upptäcka bedrägeriförsök, vilket är
             en stor fördel för vår verksamhet. Sammantaget är Xpektor en mycket
              komplett tjänst som har ändrat vårt arbetssätt."`}</i>
            <br />
            <br />
            <p
              className="section-text"
              style={{ width: '100%', textAlign: 'right' }}
            >
              - OPR Företagslån
            </p>
          </p>
        </div>
      </Background>
      <Background color1="#0316F3">
        <div className="section post-intro-section">
          <h1>Kreditbedömning på en ny nivå</h1>
          <div className="post-intro-content">
            <div className="image-column">
              <img src={FinanceProduct} alt="product_image" />
            </div>
            <div className="text-column">
              <h3>
                Xpektor hjälper dig att ta snabbare och bättre kreditbeslut med
                betydligt lägre risk.
              </h3>
              <p className="section-text">
                Med Xpektor får du som kreditgivare tillgång till dina kunders
                bokföring, kontotransaktioner, skattekonto,
                myndighetsinformation och mycket mer - allt i realtid. Xpektor
                är verktyget som underlättar kreditbeslut, identifierar
                affärsmöjligheter och minskar risken för bedrägerier och
                penningtvätt.
              </p>
              <RoundedButton text="Boka demo" fillCyan onClick={OpenDemoLink} />
            </div>
          </div>
        </div>
      </Background>
      <Background color1="#000B4F" color2="#010024">
        <HelpOfferSection
          image={HelpOffer2}
          title="Xpektor hjälper dig fatta trygga, säkra och snabba kreditbeslut"
          points={[
            'Snabbare handläggningstider',
            'Ökad lånevolym',
            'Fler affärsmöjligheter',
            'Robustare kreditbedömningar och sänkt kreditrisk',
            'Identifiering av potentiella bedrägerier och penningtvätt',
          ]}
        />
        <div className="section-with-element">
          <SpiderWebSection
            title="Hur fungerar det?"
            text="Med Xpektor får du som kreditgivare tillgång till dina kunders bokföring, kontotransaktioner, skattekonto, myndighetsinformation och mycket mer. Kunden ansluter enkelt sitt företag via en aktiveringslänk. Därefter analyserar Xpektor företagets återbetalningsförmåga, risker för bedrägerier och penningtvätt samt hjälper till att hitta affärsmöjligheter. Automatiskt och i realtid."
            image={FinanceWeb}
          />
          <BackgroundElement img={SwirlyLines2} yPos={-400} />
        </div>
      </Background>
      <Background end>
        <BannerSection />
        <Footer darkMode />
      </Background>
    </div>
  );
}
