/* eslint-disable no-alert */
import axios from 'axios';
import TokenService from './TokenService';
import MonkeyTokenService from './MonkeyTokenService';
import api from './ApiMiddleware';
import monkeyApi from './MonkeyApiMiddleware';

const login = async (username, password) => {
  return axios
    .post(`/api/v1/identity/login`, {
      username,
      password,
    })
    .then((response) => {
      if (response.data.token && !TokenService.getLocalAccessToken()) {
        TokenService.setToken(response.data);
      }
      return response.data;
    });
};

const refresh = async () => {
  return api.post(`/api/v1/identity/refresh`, {
    token: TokenService.getLocalAccessToken(),
    refreshToken: TokenService.getLocalRefreshToken(),
  });
};

const decode = async (token) => {
  return axios
    .get(`/api/v1/identity/state/${token}`)
    .then((response) => {
      if (response.data.authResult.token && response.data.stateTargetLocation) {
        // If external auth
        if (response.data.stateTargetLocation === 4) {
          MonkeyTokenService.setToken(response.data.authResult);

          if (response.data.stateAction === 0) {
            return `/authorization?ext=${response.data.externalStateId}`;
          }
          if (response.data.stateAction === 3) {
            return `?clientId=${response.data.targetId}&ext=${response.data.externalStateId}`;
          }
          return `/authorization?action=auth&ext=${response.data.externalStateId}`;
        }

        // Otherwise presigned or internal flow (internal flow will be removed)
        TokenService.setToken(response.data.authResult);
        switch (response.data.stateAction) {
          case 0: // Create client
            return `/settings/clients?customerId=${response.data.authResult.user.customerId}`;
          case 1: // Update client
            return `/settings/clients`; // Isn't used anymore.
          case 2: // Login
            return response.data.stateTargetLocation === 0
              ? `/companyinfo/${response.data.targetId}`
              : `/dashboard`;
          case 3: // Authorize bank.
            return `?clientId=${response.data.targetId}`;
          default:
            return false;
        }
      }
      return false;
    })
    .catch((error) => {
      if (error.response.status === 404) {
        alert('Token har gått ut');
        return `/dashboard`;
      }
      if (error.response.status === 500) {
        alert('Något gick fel försök igen om en stund');
        return `/dashboard`;
      }
      return false;
    });
};

const decodePresigned = async (token) => {
  return axios
    .get(`/api/v1/identity/state/${token}`)
    .then((response) => {
      if (response.data.authResult.token) {
        TokenService.setToken(response.data.authResult);
        switch (response.data.stateTargetLocation) {
          case 0: // Create client
            return `/companyinfo/${response.data.targetId}`;
          case 1: // Update client
            return `/dashboard`;
          default: // Login
            return `/dashboard`;
        }
      }
      return false;
    })
    .catch((error) => {
      if (error.response.status === 404) {
        alert('Token har gått ut');
        return `/dashboard`;
      }
      if (error.response.status === 500) {
        alert('Något gick fel försök igen om en stund');
        return `/dashboard`;
      }
      return false;
    });
};

const decodeExternal = async (token) => {
  return axios
    .get(`/api/v1/identity/state/auth/${token}`)
    .then((response) => {
      if (response.data.authResult.token) {
        MonkeyTokenService.setToken(response.data.authResult);
        return response.data;
      }
      return null;
    })
    .catch(() => {
      return null;
    });
};

const decodeKYC = async (token) => {
  return axios
    .get(`/api/v1/identity/state/kyc/${token}`)
    .then((response) => {
      if (response.data.authResult.token) {
        MonkeyTokenService.setToken(response.data.authResult);
        return response.data;
      }
      return null;
    })
    .catch(() => {
      return null;
    });
};

const getAllExternalStates = async () => {
  return api.get(`/api/v1/state/external/all`);
};

const createExternalAuthState = async (clientId, redirectUrl) => {
  return api.post(`/api/v1/state/external`, {
    clientId,
    redirectUrl,
  });
};

const getExternalAuthState = async (token) => {
  return monkeyApi.get(`/api/v1/state/external/${token}`);
};

const getClientExternalAuthState = (clientId) => {
  return api.get(`/api/v1/client/${clientId}/state/external`);
};

const deleteExternalAuthState = async (token) => {
  return api.delete(`/api/v1/state/external/${token}`);
};

const getAuthUrlFortnox = async (
  returnLocation,
  targetId,
  stateAction,
  externalStateId
) => {
  return monkeyApi
    .post(`/api/v1/identity/authurl/fortnox`, {
      returnLocation,
      stateAction,
      targetId,
      externalStateId,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 400) {
        return false;
      }

      return false;
    });
};

const getAuthUrlVisma = async (
  returnLocation,
  targetId,
  stateAction,
  externalStateId
) => {
  return monkeyApi
    .post(`/api/v1/identity/authurl/visma`, {
      returnLocation,
      stateAction,
      targetId,
      externalStateId,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 400) {
        return false;
      }

      return false;
    });
};

const generateExternalAuthUrlForClient = async (clientId, redirectUrl) => {
  return api.post(`/api/v1/identity/external/authurl`, {
    clientId,
    redirectUrl,
  });
};

const getCurrentUser = () => {
  return TokenService.getLocalUser();
};

const AuthService = {
  login,
  refresh,
  getAuthUrlFortnox,
  getAuthUrlVisma,
  getAllExternalStates,
  createExternalAuthState,
  getExternalAuthState,
  getClientExternalAuthState,
  deleteExternalAuthState,
  generateExternalAuthUrlForClient,
  decode,
  decodePresigned,
  decodeExternal,
  decodeKYC,
  getCurrentUser,
};

export default AuthService;
