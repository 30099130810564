/* eslint-disable no-alert */
import { useEffect, useMemo, useState } from 'react';
import { useHistory, withRouter, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Moment from 'moment';
import './index.css';
import './App.scss';
import Routes from './Routes';
import AuthContext from './context/auth-context';
import AuthService from './Services/AuthService';
import TokenService from './Services/TokenService';
import Navigation from './Pages/WebApp/Navigation/Navigation';
import Sidebar from './Pages/WebApp/Navigation/Sidebar';
import Footer from './Pages/WebApp/Footer/Footer';

/* let me merge this, this should work */
ReactGA.initialize('G-LR764V0GL8');
ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

function App() {
  const history = useHistory();
  const location = useLocation();
  const [authState, setAuthState] = useState({});

  const [count, setCount] = useState(0);
  const refreshCheck = async () => {
    // Check if it's time to refresh token
    setCount(count + 1);

    const now = Moment();
    const refreshTime = Moment(TokenService.getExp()).subtract(20, 'minute');

    if (now.isAfter(refreshTime)) {
      await AuthService.refresh()
        .then((response) => {
          TokenService.setToken(response.data);
        })
        .catch(() => {
          alert('Utloggad');
          TokenService.removeToken();
          localStorage.clear();
          sessionStorage.clear();
          setAuthState((state) => ({ ...state, user: null }));
          history.push('/login');
        });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => refreshCheck(), 600000); // Every 10 minutes
    return () => clearTimeout(timer);
  }, [count]);

  useEffect(() => {
    const user = TokenService.getLocalUser();
    if (!user) history.push('/not-found');

    setAuthState((state) => ({ ...state, user }));

    const now = Moment();
    const tokenExp = Moment(TokenService.getExp()).add(1380, 'minute');

    if (now.isAfter(tokenExp)) {
      alert('Utloggad på grund av inaktivitet');
      TokenService.removeToken();
      localStorage.clear();
      sessionStorage.clear();
      setAuthState((state) => ({ ...state, user: null }));
      history.push('/login');
    }
  }, [location, history]);

  const authContextValue = useMemo(
    () => [authState, setAuthState],
    [authState]
  );

  return (
    <div className="App">
      {Object.keys(authState).length !== 0 ? (
        <AuthContext.Provider value={authContextValue}>
          <Sidebar />
          <div className="vertical-content">
            <Navigation />
            <div className="app-content-container">
              <Routes />
            </div>
            <Footer />
          </div>
        </AuthContext.Provider>
      ) : (
        ''
      )}
    </div>
  );
}

export default withRouter(App);
