/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import Moment from 'moment';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import ClientService from '../../../../Services/ClientService';
import KYCService from '../../../../Services/KYCService';
import StatusService from '../../../../Services/StatusService';
import Module from './Module';
import DataPanel from '../../../../Components/DataPanel/DataPanel';
import LoadingBar from '../../../../Components/LoadingBar/LoadingBar';
import XpektorButton from '../../../../Components/XpektorButton/XpektorButton';

export default function Regulatory({ client, skvConnected, onPdfClick }) {
  const [regulatoryInfo, setRegulatoryInfo] = useState({});
  const [payrollData, setPayrollData] = useState([]);
  const [activeDirectors, setActiveDirectors] = useState([]);
  const [inactiveDirectors, setInactiveDirectors] = useState([]);
  const [ongoingMatters, setOngoingMatters] = useState([]);
  const [fetchingRegulatory, setFetchingRegulatory] = useState(false);
  const [regulatoryStatus, setRegulatoryStatus] = useState(null);

  const [kycStatus, setKYCStatus] = useState({});
  const [kycFormUrl, setKYCFormUrl] = useState('');

  const [displayingAllDirectors, setDisplayingAllDirectors] = useState(false);

  async function CreateNewKYCProcess() {
    await KYCService.createKYCState(client.id)
      .then(() => {
        KYCService.getKYCOverview(client.id)
          .then((response) => {
            setKYCStatus(response.data);
            setKYCFormUrl(
              `${window.location.protocol}//${window.location.host}/kyc-form?state=${response.data.stateId}`
            );
          })
          .catch(() => {});
      })
      .catch();
  }

  useEffect(() => {
    const getData = async () => {
      setFetchingRegulatory(true);

      await ClientService.getRegulatoryInfo(client.id)
        .then((response) => {
          setRegulatoryInfo(response.data);
          setActiveDirectors(response.data.activeDirectors);
          setInactiveDirectors(response.data.inactiveDirectors);
          setPayrollData(response.data.payrollData);
          setOngoingMatters(response.data.ongoingMatters);
        })
        .catch(() => {
          setRegulatoryInfo(null);
        })
        .finally(() => {
          setFetchingRegulatory(false);
        });
    };
    const getKYCData = async () => {
      await KYCService.getKYCOverview(client.id)
        .then((response) => {
          setKYCStatus(response.data);
          setKYCFormUrl(
            `${window.location.protocol}//${window.location.host}/kyc-form?state=${response.data.stateId}`
          );
        })
        .catch(() => {
          setKYCStatus(null);
        });
    };
    if (Object.keys(client).length !== 0 && client.errorCode === null) {
      getData().then(() => {
        getKYCData();
      });
    }
  }, [client]);

  useEffect(() => {
    const getData = async () => {
      await StatusService.getRegulatoryLatest(client.id)
        .then((response) => {
          setRegulatoryStatus(response.data);
        })
        .catch(() => {});
    };
    if (skvConnected === true) {
      getData();
    }
  }, [skvConnected, client]);

  async function ToggleAllDirectors() {
    if (!displayingAllDirectors) {
      setDisplayingAllDirectors(true);
    } else {
      setDisplayingAllDirectors(false);
    }
  }

  const sortedPayrollData = payrollData.sort(
    (a, b) => new Date(b.payrollDate) - new Date(a.payrollDate)
  );

  return (
    <Module>
      {client.errorCode !== null ? (
        <p className="module-empty-text">{client.errorCode}</p>
      ) : fetchingRegulatory ? (
        <LoadingBar active status="Hämtar data..." relative />
      ) : regulatoryInfo === null ? (
        <p className="module-empty-text">Finns ingen data.</p>
      ) : (
        <>
          <Module.Nav>
            <div />
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
              <XpektorButton
                icon={faDownload}
                content="Spara som PDF"
                onClick={() =>
                  onPdfClick(() =>
                    document.getElementById('regulatory-pdf-wrapper')
                  )
                }
              />
            </div>
          </Module.Nav>
          <div id="regulatory-pdf-wrapper">
            <Module.DataSection>
              <Module.DataColumn>
                <DataPanel title="Företagsinformation">
                  <DataPanel.ValueEntry
                    label="Företagstyp:"
                    value={regulatoryInfo?.companyType ?? 'Uppgift saknas'}
                  />
                  <DataPanel.ValueEntry
                    label="Aktivt:"
                    value={
                      regulatoryInfo
                        ? regulatoryInfo.companyActive === true
                          ? 'Ja'
                          : 'Nej'
                        : 'Uppgift Saknas'
                    }
                  />
                  <DataPanel.ValueEntry
                    label="Antal anställda:"
                    value={regulatoryInfo?.nrEmployees ?? 'Uppgift saknas'}
                  />

                  <DataPanel.ValueEntry
                    label="Senaste adressändring:"
                    value={
                      regulatoryInfo?.dateAddressChanged
                        ? Moment(regulatoryInfo.dateAddressChanged).format(
                            'YYYY-MM-DD'
                          )
                        : 'Uppgift saknas'
                    }
                  />
                  <DataPanel.ValueEntry
                    label="Tidigare namn:"
                    value={regulatoryInfo?.oldName ?? 'Uppgift saknas'}
                  />
                  <DataPanel.ValueEntry
                    label="Namn på innehavare (enskild firma):"
                    value={regulatoryInfo?.nonLtdOwnerName ?? 'Uppgift saknas'}
                  />

                  <DataPanel.ValueEntry
                    label="Moderbolag:"
                    value={regulatoryInfo?.groupMotherName ?? 'Uppgift saknas'}
                  />
                </DataPanel>
                <DataPanel
                  title="Inbetalda sociala avgifter"
                  scroll
                  height={500}
                >
                  {sortedPayrollData.map((data) => {
                    return (
                      <DataPanel.DoubleValueEntry
                        key={data.id}
                        title={`${data.fee} kr`}
                        valueOne={Moment(data.payrollDate).format('YYYY-MM-DD')}
                        valueTwo={Moment(data.registerDate).format(
                          'YYYY-MM-DD'
                        )}
                        valueOneName="Datum:"
                        valueTwoName="Registrerat:"
                      />
                    );
                  })}
                </DataPanel>
              </Module.DataColumn>
              <Module.DataColumn>
                <DataPanel title="Styrelsemedlemmar">
                  <DataPanel.Subtitle title="AKTIVA" green />
                  {activeDirectors.length === 0 ? (
                    <DataPanel.ValueEntry label="Ingen Data" />
                  ) : (
                    activeDirectors.map((director, index) => {
                      return (
                        (!displayingAllDirectors ? index < 5 : true) && (
                          <DataPanel.DoubleValueEntry
                            key={director.id}
                            title={director.name}
                            subTitle={director.function}
                            valueOne={Moment(director.appointmentDate).format(
                              'YYYY-MM-DD'
                            )}
                            valueTwo={
                              director.exitingDate
                                ? Moment(director.exitingDate).format(
                                    'YYYY-MM-DD'
                                  )
                                : null
                            }
                            valueOneName="Tillträdde:"
                            valueTwoName="Avgick:"
                          />
                        )
                      );
                    })
                  )}
                  {displayingAllDirectors && (
                    <DataPanel.Subtitle title="INAKTIVA" red />
                  )}
                  {displayingAllDirectors ? (
                    inactiveDirectors.length !== 0 ? (
                      inactiveDirectors.map((director) => {
                        return (
                          <DataPanel.DoubleValueEntry
                            key={director.id}
                            title={director.name}
                            subTitle={director.function}
                            valueOne={Moment(director.appointmentDate).format(
                              'YYYY-MM-DD'
                            )}
                            valueTwo={
                              director.exitingDate
                                ? Moment(director.exitingDate).format(
                                    'YYYY-MM-DD'
                                  )
                                : null
                            }
                            valueOneName="Tillträdde:"
                            valueTwoName="Avgick:"
                          />
                        );
                      })
                    ) : (
                      <DataPanel.ValueEntry label="Ingen Data" />
                    )
                  ) : (
                    ''
                  )}
                  <DataPanel.ExpandButton
                    text={displayingAllDirectors ? 'Minimera' : 'Expandera'}
                    expanded={displayingAllDirectors}
                    onClick={() => {
                      ToggleAllDirectors();
                    }}
                  />
                </DataPanel>
                <DataPanel title="Kontrollera rättslig information om företrädare och verkliga huvudmän samt nätverksstruktur">
                  <DataPanel.Button
                    text="Kontrollera (Kommer snart)"
                    disabled
                  />
                </DataPanel>
              </Module.DataColumn>
              <Module.DataColumn>
                <DataPanel title="Regulatoriska krav">
                  <DataPanel.ValueEntry
                    label="F-skatt:"
                    value={
                      regulatoryInfo
                        ? regulatoryInfo.fTax === true
                          ? 'Ja'
                          : regulatoryInfo.fTaxDeregistrationReason !== null
                          ? `Avregistrerad: ${regulatoryInfo.fTaxDeregistrationReason}`
                          : 'Nej'
                        : 'Uppgift saknas'
                    }
                    valueType=""
                  />
                  <DataPanel.ValueEntry
                    label="Momsregistrering:"
                    value={
                      regulatoryInfo
                        ? regulatoryInfo.moms === true
                          ? 'Ja'
                          : 'Nej'
                        : 'Uppgift saknas'
                    }
                    valueType=""
                  />
                  <DataPanel.ValueEntry
                    label="Registrerad i arbetsgivarregistret:"
                    value={
                      regulatoryInfo
                        ? regulatoryInfo.empTax === true
                          ? 'Ja'
                          : 'Nej'
                        : 'Uppgift saknas'
                    }
                  />
                  <DataPanel.ValueEntry
                    label="Revisorsförbehåll:"
                    value={
                      regulatoryInfo
                        ? regulatoryInfo.auditorReservation === true
                          ? 'Ja'
                          : 'Nej'
                        : 'Uppgift saknas'
                    }
                  />
                  <DataPanel.ValueEntry
                    label="Företrädarkontroll:"
                    value={
                      regulatoryInfo
                        ? regulatoryInfo.representativeCheck === true
                          ? 'Negativ information finns'
                          : 'Ingen negativ information finns'
                        : 'Uppgift saknas'
                    }
                  />
                </DataPanel>
                {regulatoryStatus !== null && (
                  <DataPanel title="Skatteverket">
                    <DataPanel.ValueEntry
                      label="Saldo skattekonto"
                      value={regulatoryStatus.skatteverketBalance}
                    />
                  </DataPanel>
                )}
                <DataPanel title="Pågående ärenden">
                  {ongoingMatters.length === 0 ? (
                    <DataPanel.ValueEntry label="Ingen Data" />
                  ) : (
                    ongoingMatters.map((data) => {
                      return (
                        <DataPanel.DoubleValueEntry
                          key={data.id}
                          title={data.matterText}
                          subTitle={data.caseNumber}
                          valueOne={
                            data.dateAdded &&
                            Moment(data.dateAdded).format('YYYY-MM-DD')
                          }
                          valueOneName="Datum:"
                        />
                      );
                    })
                  )}
                </DataPanel>
              </Module.DataColumn>
            </Module.DataSection>
          </div>

          <div className="kyc-card">
            <div className="main-column">
              <h2>KYC</h2>
              {kycStatus === null ? (
                <p className="empty-text">Finns ingen aktiv KYC process.</p>
              ) : Object.keys(kycStatus).length === 0 ? (
                <p className="empty-text">Hämtar...</p>
              ) : (
                <div className="data-row">
                  <div className="entry-column">
                    <p className="data-title">Status:</p>
                    <p>
                      {kycStatus.signed
                        ? 'Signerat'
                        : kycStatus.submitted
                        ? 'Väntar på underskrift'
                        : 'Ej ifyllt'}
                    </p>
                  </div>
                  <div className="entry-column">
                    <p className="data-title">Datum skapat:</p>
                    <p>
                      {Moment.utc(kycStatus.dateCreated)
                        .local()
                        .format('YYYY-MM-DD')}
                    </p>
                  </div>
                  <div className="entry-column">
                    <p className="data-title">Senast besökt:</p>
                    <p>
                      {kycStatus.lastAccessed
                        ? Moment.utc(kycStatus.lastAccessed)
                            .local()
                            .format('YYYY-MM-DD')
                        : 'N/A'}
                    </p>
                  </div>

                  <div className="entry-column">
                    <p className="data-title">Formulärtyp:</p>
                    <p>N/A</p>
                  </div>
                  <div className="entry-column">
                    <p className="data-title">Datum signerat:</p>
                    <p>
                      {kycStatus.dateSealed
                        ? Moment.utc(kycStatus.dateSealed)
                            .local()
                            .format('YYYY-MM-DD')
                        : 'N/A'}
                    </p>
                  </div>
                </div>
              )}
              <div className="button-row">
                {kycStatus === null ? (
                  <XpektorButton
                    content="Starta ny process"
                    onClick={() => {
                      CreateNewKYCProcess();
                    }}
                  />
                ) : (
                  <>
                    {kycStatus.submitted === false ? (
                      <>
                        <XpektorButton
                          content="Kopiera länk"
                          onClick={() => {
                            navigator.clipboard.writeText(kycFormUrl);
                          }}
                        />
                        <XpektorButton
                          content="Öppna formuläret"
                          onClick={() => {
                            window.open(
                              kycFormUrl,
                              '_blank',
                              'noopener,noreferrer'
                            );
                          }}
                        />
                      </>
                    ) : (
                      ''
                    )}
                    {kycStatus.signed === true ? (
                      <XpektorButton
                        content="Granska dokument"
                        styleClass="view-button"
                      />
                    ) : (
                      ''
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="trigger-column">
              {kycStatus !== null ? (
                <>
                  <p className="trigger-title">Avvikelser</p>
                  <div className="trigger-list">
                    <p className="no-triggers">Kommer snart</p>
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
          </div>
        </>
      )}
    </Module>
  );
}
