import { useHistory } from 'react-router-dom';
import HomeNav from './HomeNav';
import Footer from './Components/Footer/Footer';
import RoundedButton from '../../Components/RoundedButton/RoundedButton';
import HelpOfferSection from './Components/HelpOfferSection';
import SpiderWebSection from './Components/SpiderWebSection';
import BannerSection from './Components/BannerSection';
import Background, { BackgroundElement } from './Components/Background';
import { OpenDemoLink } from '../../Components/Utilities/HelperFunctions';

// Media
import SwirlyLines2 from '../../Images/Home/swirly_lines_2.png';
// import SwarmWave from '../../Images/Home/swarm_wave.png';
import HelpOffer3 from '../../Images/Home/help_offer_3.png';
import AdviceProduct from '../../Images/Home/product_score.png';
import AdviceWeb from '../../Images/Home/xpektor_web_advice.png';

export default function Advice() {
  const history = useHistory();

  return (
    <div className="xpektor-home">
      <div className="other-intro advice-intro">
        <HomeNav />
        <div className="section intro-section">
          <p className="section-title">Advice</p>
          <h1>Redovisning & Revision</h1>
          <div className="button-row">
            <RoundedButton
              text="Läs mer"
              fillCyan
              onClick={() => {
                history.push('/why');
              }}
            />
            <RoundedButton text="Boka demo" onClick={OpenDemoLink} />
          </div>
        </div>
      </div>
      <Background color1="#000b4f">
        <div className="intro-row-section advice-intro-row">
          <h3>
            Xpektor är din digitala medhjälpare som arbetar dygnet runt för att
            spara tid, skapa fler rådgivningstillfällen och stärka dina
            kundrelationer.
          </h3>
        </div>
      </Background>
      <Background color1="#0316F3">
        <div className="section post-intro-section">
          <h1>Ligg steget före</h1>
          <div className="post-intro-content">
            <div className="image-column">
              <img src={AdviceProduct} alt="product_image" />
            </div>
            <div className="text-column">
              <h3>Vi hjälper dina kunder, tillsammans</h3>
              <p className="section-text">
                <i>
                  {`"Som redovisningsbyrå där våra konsulter har flera kunder har det
                traditionellt sett varit svårt att få nödvändiga insikter när de
                verkligen behövs. Insikterna har oftast upptäckts i efterhand
                eller vid avstämningar, vilket gör att vi kan ha tappat
                nödvändiga dagar/veckor för att hantera detta. Men med Xpektor
                så får vi äntligen:`}
                  <br />
                  <br />
                  {`- De insikter vi behöver istället för "allt på en gång"`}
                  <br />
                  - Viktiga insikter i tid för att kunna agera och skapa
                  mervärde
                  <br />
                  - Insikter vi inte visste vi behövde för alla våra kunder. Ex
                  har bolaget en vettig försäkring? Hur lång betaltid är det på
                  kundens kundinbetalningar?
                  <br />
                  {`- Ett verktyg som lär våra juniora konsulter hur de kan
                börja agera rådgivare"`}
                </i>
                <br />
                <br />
                <p
                  className="section-text"
                  style={{ width: '100%', textAlign: 'right' }}
                >
                  - YourCFO
                </p>
              </p>
              <RoundedButton text="Boka demo" fillCyan onClick={OpenDemoLink} />
            </div>
          </div>
        </div>
      </Background>
      <Background color1="#000B4F" color2="#010024">
        <HelpOfferSection
          image={HelpOffer3}
          title="Löpande rådgivning i realtid"
          points={[
            'Stärk ert värdeerbjudande och öka era intäkter',
            'Spara tid och bygg starkare kundrelationer',
            'Stöd juniora konsulter i att bli mer rådgivande',
            'Ta emot realtidsnotiser och prioritera kunder smidigare',
            'Identifiera risker för bedrägeri och penningtvätt',
          ]}
        />
        <div className="section-with-element">
          <SpiderWebSection
            title="Hur fungerar det?"
            text="Med Xpektor får du som rådgivare tillgång till dina kunders bokföring, kontotransaktioner, skattekonto, myndighetsinformation och mycket mer. Kunden ansluter enkelt sitt företag via en aktiveringslänk. Därefter analyserar Xpektor finansiella risker, upptäcker avvikelser och skapar värdefulla insikter. Detta gör att du kan ge bättre rådgivning, förebygga problem och stärka kundrelationer. Automatiskt och i realtid."
            image={AdviceWeb}
          />
          <BackgroundElement img={SwirlyLines2} yPos={-400} />
        </div>
      </Background>
      <Background end>
        <BannerSection />
        <Footer darkMode />
      </Background>
    </div>
  );
}
