/* eslint-disable react/prop-types */
import './HelpOfferSection.scss';
import RoundedButton from '../../../Components/RoundedButton/RoundedButton';
import { OpenDemoLink } from '../../../Components/Utilities/HelperFunctions';

export default function HelpOfferSection({ image, title, points }) {
  return (
    <div className="help-offer-wrapper">
      <div className="image-column">
        <img src={image} alt="Placeholder" />
      </div>
      <div className="content-column">
        <p className="section-title">FÖRDELAR MED XPEKTOR</p>
        <h2>{title}</h2>
        <div className="point-column">
          {points.map((point) => {
            return (
              <div key={point} className="point-container">
                <div className="divider" />
                <div className="point-row">
                  <div className="point-image" />
                  <p>{point}</p>
                </div>
              </div>
            );
          })}
        </div>
        <RoundedButton text="Boka demo" fillBlue onClick={OpenDemoLink} />
      </div>
    </div>
  );
}
