/* eslint-disable react/prop-types */
import RoundedButton from '../../../Components/RoundedButton/RoundedButton';
import { OpenDemoLink } from '../../../Components/Utilities/HelperFunctions';

export default function BannerSection() {
  return (
    <div className="section banner-section">
      <div className="background">
        <p className="section-title">TESTA XPEKTOR IDAG</p>
        <h2>
          Att ta beslut i realtid
          <br /> har aldrig varit enklare
        </h2>
        <p className="section-text">
          Spara tid och minska risk redan idag. Med Xpektor får du full kontroll
          över dina kunder utan manuell handpåläggning. Kontakta oss för mer
          info.
        </p>
        <RoundedButton text="Starta idag" fillCyan onClick={OpenDemoLink} />
      </div>
    </div>
  );
}
