import './KYCForm.scss';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import AuthService from '../../../Services/AuthService';
import {
  ClassicForm,
  FormInput,
  FormTextArea,
  FormFileUpload,
  FormDropdown,
} from '../../../Components/XpektorForms/XpektorForms';
import RadioButton from '../../../Components/RadioButton/RadioButton';
import XpektorButton from '../../../Components/XpektorButton/XpektorButton';
import KYCService from '../../../Services/KYCService';
import LoadingBar from '../../../Components/LoadingBar/LoadingBar';

export default function KYCForm() {
  const maxSteps = 7;
  const [formStep, setFormStep] = useState(1);

  const [kycState, setKYCState] = useState({});
  const { search } = useLocation();
  const state = new URLSearchParams(search).get('state');

  const [formType, setFormType] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [orgNr, setOrgNr] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [bankAccountType, setBankAccountType] = useState(1);
  const [accountNumber, setAccountNumber] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [phoneNrContactPerson, setPhoneNrContactPerson] = useState('');
  const [webAddress, setWebAddress] = useState('');
  const [eCommercePlatform, setECommercePlatform] = useState('');
  const [settlementEmail, setSettlementEmail] = useState('');
  const [incorporationDate, setIncorporationDate] = useState('');
  const [nrEmployees, setNrEmployees] = useState('');
  const [
    estimatedMonthlyRevenueInstitute,
    setEstimatedMonthlyRevenueInstitute,
  ] = useState('');
  const [averageAmountInvoice, setAverageAmountInvoice] = useState('');
  const [nrOfTransactionsInstitute, setNrOfTransactionsInstitute] =
    useState('');
  const [businessAbroad, setBusinessAbroad] = useState(false);
  const [businessAbroadDescription, setBusinessAbroadDescription] =
    useState('');
  const [operationsOutsideEUESS, setOperationsOutsideEUESS] = useState(false);
  const [
    operationsOutsideEUESSDescription,
    setOperationsOutsideEUESSDescription,
  ] = useState('');
  const [coreBusinessDescription, setCoreBusinessDescription] = useState('');
  const [businessFinanceDescription, setBusinessFinanceDescription] =
    useState('');
  const [loanPurpose, setLoanPurpose] = useState('');

  const [foreignTransactionAmount, setForeignTransactionAmount] = useState(10);
  const [nrOfForeignTransactions, setNrOfForeignTransactions] = useState(0);
  const [currencyExchange, setCurrencyExchange] = useState(false);
  const [cryptoCurrencyExchange, setCryptoCurrencyExchange] = useState(false);
  const [handlingCash, setHandlingCash] = useState(false);
  const [cashMonthly, setCashMonthly] = useState('');
  const [nrOfExtraRepayments, setNrOfExtraRepayments] = useState(0);
  const [companyHierarchyDescription, setCompanyHierarchyDescription] =
    useState('');
  const [companyHierarchyFile, setCompanyHierarchyFile] = useState({});
  const [detailedBusinessDescription, setDetailedBusinessDescription] =
    useState('');
  const [detailedLoanPurpose, setDetailedLoanPurpose] = useState('');
  const [futurePlans, setFuturePlans] = useState('');
  const [estimatedRevenueThreeMonths, setEstimatedRevenueThreeMonths] =
    useState('');
  const [estimatedRevenueSixMonths, setEstimatedRevenueSixMonths] =
    useState('');
  const [estimatedRevenueTwelveMonths, setEstimatedRevenueTwelveMonths] =
    useState('');
  const [estimatedResultThreeMonths, setEstimatedResultThreeMonths] =
    useState('');
  const [estimatedResultSixMonths, setEstimatedResultSixMonths] = useState('');
  const [estimatedResultTwelveMonths, setEstimatedResultTwelveMonths] =
    useState('');

  const [revenueMonthly, setRevenueMothly] = useState('');
  const [sellingService, setSellingService] = useState(false);
  const [sellingProduct, setSellingProduct] = useState(false);
  const [otherRevenueSource, setOtherRevenueSource] = useState(false);
  const [serviceDescription, setServiceDescription] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [otherRevenueSourceDescription, setOtherRevenueSourceDescription] =
    useState('');
  const [nrOfWithdrawals, setNrOfWithdrawals] = useState('');

  const [boardMembers, setBoardMembers] = useState([
    {
      id: 'board-member-0',
      name: '',
      socSecurityNumber: '',
      isSignatory: true,
    },
  ]);
  function AddBoardMemberEntry() {
    setBoardMembers([
      ...boardMembers,
      {
        id: `board-member-${boardMembers.length}`,
        name: '',
        socSecurityNumber: '',
        isSignatory: false,
      },
    ]);
  }

  const [otherSignatoriesExist, setOtherSignatoriesExist] = useState(false);
  const [otherSignatories, setOtherSignatories] = useState([]);
  function AddOtherSignatoryEntry() {
    setOtherSignatories([
      ...otherSignatories,
      {
        id: `other-signatory-${otherSignatories.length}`,
        name: '',
        socSecurityNumber: '',
      },
    ]);
  }

  const [beneficialOwnerPersons, setBeneficialOwnerPersons] = useState([]);
  const [beneficialOwnerCompanies, setBeneficialOwnerCompanies] = useState([]);
  function AddBeneficialOwnerPersonEntry() {
    setBeneficialOwnerPersons([
      ...beneficialOwnerPersons,
      {
        id: `beneficial-owner-persons-${beneficialOwnerPersons.length}`,
        name: '',
        socSecurityNumber: '',
        shares: '',
        PEP: false,
        RCA: false,
      },
    ]);
  }
  function AddBeneficialOwnerCompanyEntry() {
    setBeneficialOwnerCompanies([
      ...beneficialOwnerCompanies,
      {
        id: `beneficial-owner-companies-${beneficialOwnerCompanies.length}`,
        name: '',
        orgNumber: '',
        shares: '',
      },
    ]);
  }

  const [otherInfluentialOwnersPresent, setOtherInfluentialOwnersPresent] =
    useState(false);
  const [otherInfluentialOwnerPersons, setOtherInfluentialOwnerPersons] =
    useState([]);
  const [otherInfluentialOwnerCompanies, setOtherInfluentialOwnerCompanies] =
    useState([]);
  function AddOtherInfluentialPerson() {
    setOtherInfluentialOwnerPersons([
      ...otherInfluentialOwnerPersons,
      {
        id: `other-influential-owner-person-${otherInfluentialOwnerPersons.length}`,
        name: '',
        socSecurityNumber: '',
        PEP: '',
        RCA: '',
        decscriptionOfInfluenceInCompany: '',
      },
    ]);
  }
  function AddOtherInfluentialCompany() {
    setOtherInfluentialOwnerCompanies([
      ...otherInfluentialOwnerCompanies,
      {
        id: `other-influential-owner-company-${otherInfluentialOwnerCompanies.length}`,
        companyName: '',
        orgNr: '',
        decscriptionOfInfluenceInCompany: '',
      },
    ]);
  }

  const [possibleSubjects, setPossibleSubjects] = useState([]);

  function CanGoNext() {
    if (formStep < maxSteps) {
      if (formStep === 2) {
        if (bankAccountType !== 0) {
          return true;
        }
      }
      return true;
    }

    return true;
  }

  useEffect(() => {
    const decodeState = async () => {
      if (state) {
        const response = await AuthService.decodeKYC(state);
        if (response != null) {
          setKYCState(response);
        } else {
          setKYCState(null);
        }
      }
    };
    decodeState();
  }, [state]);

  useEffect(() => {
    if (formStep === 7) {
      const newPossibleSubjects = [];
      for (let i = 0; i < boardMembers.length; i += 1) {
        if (boardMembers[i].isSignatory) {
          const existingPossibleSubject = newPossibleSubjects.find(
            (existing) =>
              existing.socSecurityNumber === boardMembers[i].socSecurityNumber
          );
          if (!existingPossibleSubject) {
            newPossibleSubjects.push({
              id: `possible-subject-${newPossibleSubjects.length}`,
              name: boardMembers[i].name,
              socSecurityNumber: boardMembers[i].socSecurityNumber,
              email: '',
              idFile: {},
              selected: false,
            });
          }
        }
      }
      for (let i = 0; i < otherSignatories.length; i += 1) {
        const existingPossibleSubject = newPossibleSubjects.find(
          (existing) =>
            existing.socSecurityNumber === otherSignatories[i].socSecurityNumber
        );
        if (!existingPossibleSubject) {
          newPossibleSubjects.push({
            id: `possible-subject-${newPossibleSubjects.length}`,
            name: otherSignatories[i].name,
            socSecurityNumber: otherSignatories[i].socSecurityNumber,
            email: '',
            idFile: {},
            selected: false,
          });
        }
      }
      setPossibleSubjects(newPossibleSubjects);
    }
  }, [formStep, boardMembers, otherSignatories]);

  const [subjects, setSubjects] = useState([]);

  async function SubmitKYCForm() {
    const selectedSubjects = [];

    for (let i = 0; i < possibleSubjects.length; i += 1) {
      if (possibleSubjects[i].selected === true) {
        selectedSubjects.push({
          name: possibleSubjects[i].name,
          email: possibleSubjects[i].email,
          socSecurityNumber: possibleSubjects[i].socSecurityNumber,
        });
      }
    }
    setSubjects(selectedSubjects);

    await KYCService.submitKYCForm(
      kycState.clientId,
      kycState.id,
      formType,
      companyName,
      orgNr,
      boardMembers,
      otherSignatories,
      address,
      city,
      zipCode,
      contactPerson,
      settlementEmail,
      phoneNrContactPerson,
      bankAccountType,
      accountNumber,
      webAddress,
      eCommercePlatform,
      incorporationDate,
      nrEmployees,
      estimatedMonthlyRevenueInstitute,
      averageAmountInvoice,
      nrOfTransactionsInstitute,
      businessAbroadDescription,
      coreBusinessDescription,
      businessFinanceDescription,
      loanPurpose,
      foreignTransactionAmount,
      nrOfForeignTransactions,
      beneficialOwnerPersons,
      beneficialOwnerCompanies,
      companyHierarchyDescription,
      otherInfluentialOwnersPresent,
      otherInfluentialOwnerPersons,
      // otherInfluentialOwnerCompanies,
      detailedLoanPurpose,
      detailedBusinessDescription,
      futurePlans,
      estimatedRevenueThreeMonths,
      estimatedRevenueSixMonths,
      estimatedRevenueTwelveMonths,
      estimatedResultThreeMonths,
      estimatedResultSixMonths,
      estimatedResultTwelveMonths,
      sellingService,
      sellingProduct,
      otherRevenueSource,
      serviceDescription,
      productDescription,
      otherRevenueSourceDescription,
      operationsOutsideEUESS,
      operationsOutsideEUESSDescription,
      currencyExchange,
      cryptoCurrencyExchange,
      revenueMonthly,
      handlingCash,
      cashMonthly,
      nrOfExtraRepayments,
      nrOfWithdrawals,
      companyHierarchyFile,
      subjects
    );
  }

  return (
    <div className="kyc-form-wrapper">
      <div className="kyc-modal">
        {kycState === null ? (
          <p className="unauthorized-text">Ej giltig länk</p>
        ) : Object.keys(kycState).length === 0 ? (
          <LoadingBar status="Vänligen vänta..." active />
        ) : (
          <>
            <div className="kyc-header">
              <h1 className="kyc-title">Xpektor Kundkännedom</h1>
              <p className="normal-text">
                Enligt lagen om penningtvätt och finansiering av terrorism
                behöver vi ha god kännedom om dig som kund för att kunna
                fortsätta erbjuda dig våra tjänster och produkter. Frågorna är
                obligatoriska och vi ställer dem till alla våra kunder.
                <br />
                <br />
                {`Har du frågor kring din legitimering, kontakta <upplysning@xpektor.se>.
             Behöver du hjälp eller vägledning? Du är välkommen att kontakta oss på
              0769-424744 om du har frågor. Verifiering sker via Sajnat, som verifierar
               identitet och information online med BankID.`}
              </p>
              <div className="step-display">
                <p>{`Steg ${formStep}/${maxSteps}`}</p>
                <div className="step-lights">
                  <div
                    className={`step-light ${
                      formStep === 1 ? 'active-light' : ''
                    }`}
                  />
                  <div
                    className={`step-light ${
                      formStep === 2 ? 'active-light' : ''
                    }`}
                  />
                  <div
                    className={`step-light ${
                      formStep === 3 ? 'active-light' : ''
                    }`}
                  />
                  <div
                    className={`step-light ${
                      formStep === 4 ? 'active-light' : ''
                    }`}
                  />
                  <div
                    className={`step-light ${
                      formStep === 5 ? 'active-light' : ''
                    }`}
                  />
                  <div
                    className={`step-light ${
                      formStep === 6 ? 'active-light' : ''
                    }`}
                  />
                  <div
                    className={`step-light ${
                      formStep === 7 ? 'active-light' : ''
                    }`}
                  />
                </div>
              </div>
            </div>
            <div className="kyc-content">
              {formStep === 1 ? (
                <div className="form-type-select">
                  <button
                    type="button"
                    onClick={() => setFormType('0')}
                    className={formType === '0' ? 'active-button' : ''}
                  >
                    Lån
                  </button>
                  <button
                    type="button"
                    onClick={() => setFormType('1')}
                    className={formType === '1' ? 'active-button' : ''}
                  >
                    Företagskredit
                  </button>
                  <button
                    type="button"
                    onClick={() => setFormType('2')}
                    className={formType === '2' ? 'active-button' : ''}
                  >
                    Factoring
                  </button>
                  <button
                    type="button"
                    onClick={() => setFormType('3')}
                    className={formType === '3' ? 'active-button' : ''}
                  >
                    Orderfinansiering
                  </button>
                  <button
                    type="button"
                    onClick={() => setFormType('4')}
                    className={formType === '4' ? 'active-button' : ''}
                  >
                    Leasing
                  </button>
                </div>
              ) : formStep === 2 ? (
                <ClassicForm>
                  <div className="form-section">
                    <div className="form-grid">
                      <FormInput
                        label="Bolagsnamn"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                      <FormInput
                        label="Organisationsnummer"
                        value={orgNr}
                        onChange={(e) => setOrgNr(e.target.value)}
                      />
                      <FormInput
                        label="Adress"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                      <FormInput
                        label="Postort"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                      <FormInput
                        label="Postnummer"
                        value={zipCode}
                        onChange={(e) => setZipCode(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-section">
                    <div className="form-row">
                      <FormInput
                        label="Kontonummer"
                        value={accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)}
                      />
                      <div className="form-row">
                        <FormDropdown
                          label="Kontotyp"
                          onChange={(e) => setBankAccountType(e.target.value)}
                          defaultValue={1}
                        >
                          <option value={1}>Bankgiro</option>
                          <option value={2}>Plusgiro</option>
                          <option value={3}>Bankkonto</option>
                          <option value={4}>IBAN</option>
                        </FormDropdown>
                        <div />
                      </div>
                    </div>
                  </div>
                  <div className="form-section">
                    <div className="form-column">
                      <p className="small-title">Styrelsemedlemmar</p>
                      {boardMembers.map((boardMember, index) => {
                        return (
                          <div className="board-row" key={boardMember.id}>
                            <FormInput
                              label="Fullständigt namn"
                              value={boardMember.name}
                              onChange={(e) => {
                                const newArray = [...boardMembers];
                                newArray[index].name = e.target.value;
                                setBoardMembers(newArray);
                              }}
                            />
                            <FormInput
                              label="Personnummer"
                              value={boardMember.socSecurityNumber}
                              onChange={(e) => {
                                const newArray = [...boardMembers];
                                newArray[index].socSecurityNumber =
                                  e.target.value;
                                setBoardMembers(newArray);
                              }}
                            />
                            <FormDropdown
                              label="Är firmatecknare?"
                              onChange={(e) => {
                                const newArray = [...boardMembers];
                                newArray[index].isSignatory = e.target.value;
                                setBoardMembers(newArray);
                              }}
                              defaultValue
                            >
                              <option value>Ja</option>
                              <option>Nej</option>
                            </FormDropdown>
                            <button
                              type="button"
                              className="modify-button remove-button"
                              disabled={index === 0}
                              onClick={() =>
                                setBoardMembers(
                                  boardMembers.filter((filterBoardMember) => {
                                    return filterBoardMember !== boardMember;
                                  })
                                )
                              }
                            >
                              <FontAwesomeIcon
                                className="icon"
                                icon={faMinus}
                              />
                              <p>Ta bort</p>
                            </button>
                          </div>
                        );
                      })}
                      <button
                        className="modify-button"
                        type="button"
                        onClick={() => AddBoardMemberEntry()}
                      >
                        <FontAwesomeIcon className="icon" icon={faPlus} />
                        <p>Lägg till rad</p>
                      </button>
                    </div>
                    <div className="form-column">
                      <div className="radio-select">
                        <p className="small-title">
                          Finns det andra särskilda firmatecknare som inte är
                          del av styrelsen?
                        </p>
                        <div className="choice-row">
                          <RadioButton
                            text="Ja"
                            active={otherSignatoriesExist}
                            onClick={() => setOtherSignatoriesExist(true)}
                          />
                          <RadioButton
                            text="Nej"
                            active={!otherSignatoriesExist}
                            onClick={() => setOtherSignatoriesExist(false)}
                          />
                        </div>
                      </div>
                    </div>
                    {otherSignatoriesExist ? (
                      <div className="form-column">
                        <p className="small-title">Andra firmatecknare</p>
                        {otherSignatories.map((signatory, index) => {
                          return (
                            <div className="form-row" key={signatory.id}>
                              <FormInput
                                label="Fullständigt namn"
                                value={signatory.name}
                                onChange={(e) => {
                                  const newArray = [...otherSignatories];
                                  newArray[index].name = e.target.value;
                                  setOtherSignatories(newArray);
                                }}
                              />
                              <FormInput
                                label="Personnummer"
                                value={signatory.socSecurityNumber}
                                onChange={(e) => {
                                  const newArray = [...otherSignatories];
                                  newArray[index].socSecurityNumber =
                                    e.target.value;
                                  setOtherSignatories(newArray);
                                }}
                              />
                              <button
                                type="button"
                                className="modify-button remove-button"
                                onClick={() =>
                                  setOtherSignatories(
                                    otherSignatories.filter(
                                      (filterSignatory) => {
                                        return filterSignatory !== signatory;
                                      }
                                    )
                                  )
                                }
                              >
                                <FontAwesomeIcon
                                  className="icon"
                                  icon={faMinus}
                                />
                                <p>Ta bort</p>
                              </button>
                            </div>
                          );
                        })}
                        <button
                          className="modify-button"
                          type="button"
                          onClick={() => AddOtherSignatoryEntry()}
                        >
                          <FontAwesomeIcon className="icon" icon={faPlus} />
                          <p>Lägg till rad</p>
                        </button>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="form-section">
                    <div className="form-grid">
                      <FormInput
                        label="Kontaktperson (Fullständigt namn)"
                        value={contactPerson}
                        onChange={(e) => setContactPerson(e.target.value)}
                      />
                      <FormInput
                        label="Telefonnummer till kontaktperson"
                        value={phoneNrContactPerson}
                        onChange={(e) =>
                          setPhoneNrContactPerson(e.target.value)
                        }
                      />
                      <FormInput
                        label="Webbadress"
                        value={webAddress}
                        onChange={(e) => setWebAddress(e.target.value)}
                      />
                      <FormInput
                        label="E-handelsplattform (Om sådan finns)"
                        value={eCommercePlatform}
                        onChange={(e) => setECommercePlatform(e.target.value)}
                      />
                      {formType === '2' ? (
                        <FormInput
                          label="Email avräkningar"
                          value={settlementEmail}
                          onChange={(e) => setSettlementEmail(e.target.value)}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </ClassicForm>
              ) : formStep === 3 ? (
                <ClassicForm>
                  <div className="form-section">
                    <div className="form-grid">
                      <FormInput
                        label="Bolagets startdatum"
                        value={incorporationDate}
                        onChange={(e) => setIncorporationDate(e.target.value)}
                      />
                      <FormInput
                        label="Antal anställda"
                        value={nrEmployees}
                        onChange={(e) => setNrEmployees(e.target.value)}
                      />
                      {formType === '2' ? (
                        <>
                          <FormInput
                            label="Estimerad omsättning per mån via kreditinstitut"
                            value={estimatedMonthlyRevenueInstitute}
                            onChange={(e) =>
                              setEstimatedMonthlyRevenueInstitute(
                                e.target.value
                              )
                            }
                          />
                          <FormInput
                            label="Antal transaktioner per månad via kreditinstitut"
                            value={nrOfTransactionsInstitute}
                            onChange={(e) =>
                              setNrOfTransactionsInstitute(e.target.value)
                            }
                          />
                          <FormInput
                            label="Snittbelopp per faktura"
                            value={averageAmountInvoice}
                            onChange={(e) =>
                              setAverageAmountInvoice(e.target.value)
                            }
                          />
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div className="form-section">
                    <div className="radio-select">
                      <p className="small-title">
                        Gör ni affärer med utlandet?
                      </p>
                      <div className="choice-row">
                        <RadioButton
                          text="Ja"
                          active={businessAbroad}
                          onClick={() => setBusinessAbroad(true)}
                        />
                        <RadioButton
                          text="Nej"
                          active={!businessAbroad}
                          onClick={() => setBusinessAbroad(false)}
                        />
                      </div>
                    </div>
                    {businessAbroadDescription ? (
                      <>
                        <FormTextArea
                          label="Beskriv hur ni gör affärer med utlandet samt vilka länder som omfattas"
                          value={businessAbroadDescription}
                          onChange={(e) =>
                            setBusinessAbroadDescription(e.target.value)
                          }
                          rows={6}
                        />
                        <div className="form-row">
                          <div className="radio-select">
                            <p className="small-title">
                              Hur stora belopp tror ni kommer komma från
                              utlandet varje månad?
                            </p>
                            <div className="choice-column">
                              <RadioButton
                                text="0 - 100 000"
                                active={foreignTransactionAmount === 10}
                                onClick={() => setForeignTransactionAmount(10)}
                              />
                              <RadioButton
                                text="100 000 - 500 000"
                                active={foreignTransactionAmount === 20}
                                onClick={() => setForeignTransactionAmount(20)}
                              />
                              <RadioButton
                                text="500 000-1 000 000"
                                active={foreignTransactionAmount === 30}
                                onClick={() => setForeignTransactionAmount(30)}
                              />
                              <RadioButton
                                text="Över 1 000 000"
                                active={foreignTransactionAmount === 40}
                                onClick={() => setForeignTransactionAmount(40)}
                              />
                            </div>
                          </div>
                          <div className="radio-select">
                            <p className="small-title">
                              Hur många transaktioner per månad tror ni kommer
                              från utlandet?
                            </p>
                            <div className="choice-column">
                              <RadioButton
                                text="0"
                                active={nrOfForeignTransactions === 0}
                                onClick={() => setNrOfForeignTransactions(0)}
                              />
                              <RadioButton
                                text="1 - 2"
                                active={nrOfForeignTransactions === 1}
                                onClick={() => setNrOfForeignTransactions(1)}
                              />
                              <RadioButton
                                text="3 - 5"
                                active={nrOfForeignTransactions === 2}
                                onClick={() => setNrOfForeignTransactions(2)}
                              />
                              <RadioButton
                                text="6 - 8"
                                active={nrOfForeignTransactions === 3}
                                onClick={() => setNrOfForeignTransactions(3)}
                              />
                              <RadioButton
                                text="9 - 15"
                                active={nrOfForeignTransactions === 4}
                                onClick={() => setNrOfForeignTransactions(4)}
                              />
                              <RadioButton
                                text="Mer än 15"
                                active={nrOfForeignTransactions === 5}
                                onClick={() => setNrOfForeignTransactions(5)}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="form-section">
                    <div className="radio-select">
                      <p className="small-title">
                        Bedriver ni verksamhet utanför EU/ESS?
                      </p>
                      <div className="choice-row">
                        <RadioButton
                          text="Ja"
                          active={operationsOutsideEUESS}
                          onClick={() => setOperationsOutsideEUESS(true)}
                        />
                        <RadioButton
                          text="Nej"
                          active={!operationsOutsideEUESS}
                          onClick={() => setOperationsOutsideEUESS(false)}
                        />
                      </div>
                    </div>
                    {operationsOutsideEUESS ? (
                      <FormTextArea
                        label="Ange i vilka länder"
                        rows={6}
                        value={operationsOutsideEUESSDescription}
                        onChange={(e) =>
                          setOperationsOutsideEUESSDescription(e.target.value)
                        }
                      />
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="form-section">
                    <FormTextArea
                      label="Vilken verksamhet bedrivs eller kommer att bedrivas i bolaget?"
                      rows={6}
                      value={coreBusinessDescription}
                      onChange={(e) =>
                        setCoreBusinessDescription(e.target.value)
                      }
                    />
                    <FormTextArea
                      label="Hur finansieras verksamheten? (Ägarmedel, lån, upparbetade vinster etc)"
                      rows={6}
                      value={businessFinanceDescription}
                      onChange={(e) =>
                        setBusinessFinanceDescription(e.target.value)
                      }
                    />
                    <FormTextArea
                      label="Vad är ert syfte med att nyttja kreditinstitutets betallösning?"
                      rows={6}
                      value={loanPurpose}
                      onChange={(e) => setLoanPurpose(e.target.value)}
                    />
                  </div>
                </ClassicForm>
              ) : formStep === 4 ? (
                <ClassicForm>
                  <div className="form-section">
                    <div className="form-column">
                      <p className="medium-title">Verklig huvudman</p>
                      <p className="normal-text">
                        Ange de fysiska personer eller bolag som direkt, eller
                        indirekt, äger mer än 25% av bolaget. <br /> <br />
                        Vi behöver också veta om personen i fråga har en
                        politisk utsatt ställning (PEP) eller är en
                        familjemedlem eller arbetar mycket nära en person i
                        politisk utsatt ställning (RCA).
                      </p>
                      <p className="small-title">Personer</p>
                      {beneficialOwnerPersons.length > 0
                        ? beneficialOwnerPersons.map(
                            (beneficialOwner, index) => {
                              return (
                                <div
                                  className="beneficiary-row"
                                  key={beneficialOwner.id}
                                >
                                  <FormInput
                                    label="Fullständigt namn"
                                    value={beneficialOwner.name}
                                    onChange={(e) => {
                                      const newArray = [
                                        ...beneficialOwnerPersons,
                                      ];
                                      newArray[index].name = e.target.value;
                                      setBeneficialOwnerPersons(newArray);
                                    }}
                                  />
                                  <FormInput
                                    label="Personnummer"
                                    value={beneficialOwner.socSecurityNumber}
                                    onChange={(e) => {
                                      const newArray = [
                                        ...beneficialOwnerPersons,
                                      ];
                                      newArray[index].socSecurityNumber =
                                        e.target.value;
                                      setBeneficialOwnerPersons(newArray);
                                    }}
                                  />
                                  <FormInput
                                    label="Andel i %"
                                    value={beneficialOwner.shares}
                                    onChange={(e) => {
                                      const newArray = [
                                        ...beneficialOwnerPersons,
                                      ];
                                      newArray[index].shares = e.target.value;
                                      setBeneficialOwnerPersons(newArray);
                                    }}
                                  />
                                  <FormDropdown
                                    label="PEP eller RCA"
                                    onChange={(e) => {
                                      const newArray = [
                                        ...beneficialOwnerPersons,
                                      ];
                                      newArray[index].PEP =
                                        e.target.value === 'PEP';
                                      newArray[index].RCA =
                                        e.target.value === 'RCA';

                                      setBeneficialOwnerPersons(newArray);
                                    }}
                                  >
                                    <option selected value="none">
                                      Nej
                                    </option>
                                    <option value="PEP">PEP</option>
                                    <option value="RCA">RCA</option>
                                  </FormDropdown>
                                  <button
                                    type="button"
                                    className="modify-button remove-button"
                                    onClick={() =>
                                      setBeneficialOwnerPersons(
                                        beneficialOwnerPersons.filter(
                                          (filterBeneficialOwner) => {
                                            return (
                                              filterBeneficialOwner !==
                                              beneficialOwner
                                            );
                                          }
                                        )
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon
                                      className="icon"
                                      icon={faMinus}
                                    />
                                    <p>Ta bort</p>
                                  </button>
                                </div>
                              );
                            }
                          )
                        : ''}
                      <button
                        className="modify-button"
                        type="button"
                        onClick={() => AddBeneficialOwnerPersonEntry()}
                      >
                        <FontAwesomeIcon className="icon" icon={faPlus} />
                        <p>Lägg till person</p>
                      </button>
                    </div>
                    <div className="form-column">
                      <p className="small-title">Bolag</p>
                      {beneficialOwnerCompanies.length > 0
                        ? beneficialOwnerCompanies.map(
                            (beneficialOwner, index) => {
                              return (
                                <div
                                  className="beneficiary-row"
                                  key={beneficialOwner.id}
                                >
                                  <FormInput
                                    label="Namn"
                                    value={beneficialOwner.name}
                                    onChange={(e) => {
                                      const newArray = [
                                        ...beneficialOwnerCompanies,
                                      ];
                                      newArray[index].name = e.target.value;
                                      setBeneficialOwnerCompanies(newArray);
                                    }}
                                  />
                                  <FormInput
                                    label="Organisationsnummer"
                                    value={beneficialOwner.orgNr}
                                    onChange={(e) => {
                                      const newArray = [
                                        ...beneficialOwnerCompanies,
                                      ];
                                      newArray[index].orgNr = e.target.value;
                                      setBeneficialOwnerCompanies(newArray);
                                    }}
                                  />
                                  <FormInput
                                    label="Andel i %"
                                    value={beneficialOwner.shares}
                                    onChange={(e) => {
                                      const newArray = [
                                        ...beneficialOwnerCompanies,
                                      ];
                                      newArray[index].shares = e.target.value;
                                      setBeneficialOwnerCompanies(newArray);
                                    }}
                                  />
                                  <button
                                    type="button"
                                    className="modify-button remove-button"
                                    onClick={() =>
                                      setBeneficialOwnerCompanies(
                                        beneficialOwnerCompanies.filter(
                                          (filterBeneficialOwner) => {
                                            return (
                                              filterBeneficialOwner !==
                                              beneficialOwner
                                            );
                                          }
                                        )
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon
                                      className="icon"
                                      icon={faMinus}
                                    />
                                    <p>Ta bort</p>
                                  </button>
                                </div>
                              );
                            }
                          )
                        : ''}
                      <button
                        className="modify-button"
                        type="button"
                        onClick={() => AddBeneficialOwnerCompanyEntry()}
                      >
                        <FontAwesomeIcon className="icon" icon={faPlus} />
                        <p>Lägg till bolag</p>
                      </button>
                    </div>
                  </div>
                  <div className="form-section">
                    <FormTextArea
                      label="Beskriv bolagets ägarstruktur"
                      rows={6}
                      value={companyHierarchyDescription}
                      onChange={(e) =>
                        setCompanyHierarchyDescription(e.target.value)
                      }
                    />
                    <FormFileUpload
                      label="Bifoga karta över ägarstruktur om det finns"
                      onChange={(e) => {
                        if (e.target.files) {
                          setCompanyHierarchyFile(e.target.files[0]);
                        }
                      }}
                    />
                  </div>
                  <div className="form-section">
                    <div className="radio-select">
                      <p className="small-title">
                        Finns det personer eller bolag som på annat sätt har
                        bestämmande inflytande i företaget?
                      </p>
                      <div className="choice-row">
                        <RadioButton
                          text="Ja"
                          active={otherInfluentialOwnersPresent}
                          onClick={() => setOtherInfluentialOwnersPresent(true)}
                        />
                        <RadioButton
                          text="Nej"
                          active={!otherInfluentialOwnersPresent}
                          onClick={() =>
                            setOtherInfluentialOwnersPresent(false)
                          }
                        />
                      </div>
                    </div>
                    {otherInfluentialOwnersPresent ? (
                      <>
                        <div className="form-column">
                          <p className="small-title">Personer</p>
                          {otherInfluentialOwnerPersons.length > 0
                            ? otherInfluentialOwnerPersons.map(
                                (influentialPerson, index) => {
                                  return (
                                    <div
                                      className="influential-person-row"
                                      key={influentialPerson.id}
                                    >
                                      <FormInput
                                        label="Fullständigt namn"
                                        value={influentialPerson.name}
                                        onChange={(e) => {
                                          const newArray = [
                                            ...otherInfluentialOwnerPersons,
                                          ];
                                          newArray[index].name = e.target.value;
                                          setOtherInfluentialOwnerPersons(
                                            newArray
                                          );
                                        }}
                                      />
                                      <FormInput
                                        label="Personnummer"
                                        value={
                                          influentialPerson.socSecurityNumber
                                        }
                                        onChange={(e) => {
                                          const newArray = [
                                            ...otherInfluentialOwnerPersons,
                                          ];
                                          newArray[index].socSecurityNumber =
                                            e.target.value;
                                          setOtherInfluentialOwnerPersons(
                                            newArray
                                          );
                                        }}
                                      />
                                      <FormDropdown
                                        label="PEP eller RCA"
                                        onChange={(e) => {
                                          const newArray = [
                                            ...otherInfluentialOwnerPersons,
                                          ];
                                          newArray[index].PEP =
                                            e.target.value === 'PEP';
                                          newArray[index].RCA =
                                            e.target.value === 'RCA';

                                          setOtherInfluentialOwnerPersons(
                                            newArray
                                          );
                                        }}
                                      >
                                        <option selected value="none">
                                          Nej
                                        </option>
                                        <option value="PEP">PEP</option>
                                        <option value="RCA">RCA</option>
                                      </FormDropdown>
                                      <button
                                        type="button"
                                        className="modify-button remove-button"
                                        onClick={() =>
                                          setOtherInfluentialOwnerPersons(
                                            otherInfluentialOwnerPersons.filter(
                                              (filterInfluential) => {
                                                return (
                                                  filterInfluential !==
                                                  influentialPerson
                                                );
                                              }
                                            )
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon
                                          className="icon"
                                          icon={faMinus}
                                        />
                                        <p>Ta bort</p>
                                      </button>
                                    </div>
                                  );
                                }
                              )
                            : ''}
                          <button
                            className="modify-button"
                            type="button"
                            onClick={() => AddOtherInfluentialPerson()}
                          >
                            <FontAwesomeIcon className="icon" icon={faPlus} />
                            <p>Lägg till person</p>
                          </button>
                        </div>
                        <div className="form-column">
                          <p className="small-title">Bolag</p>
                          {otherInfluentialOwnerCompanies.length > 0
                            ? otherInfluentialOwnerCompanies.map(
                                (influentialCompany, index) => {
                                  return (
                                    <div
                                      className="influential-company-row"
                                      key={influentialCompany.id}
                                    >
                                      <FormInput
                                        label="Fullständigt namn"
                                        value={influentialCompany.name}
                                        onChange={(e) => {
                                          const newArray = [
                                            ...otherInfluentialOwnerPersons,
                                          ];
                                          newArray[index].name = e.target.value;
                                          setOtherInfluentialOwnerCompanies(
                                            newArray
                                          );
                                        }}
                                      />
                                      <FormInput
                                        label="Organisationsnummer"
                                        value={influentialCompany.orgNr}
                                        onChange={(e) => {
                                          const newArray = [
                                            ...otherInfluentialOwnerPersons,
                                          ];
                                          newArray[index].orgNr =
                                            e.target.value;
                                          setOtherInfluentialOwnerCompanies(
                                            newArray
                                          );
                                        }}
                                      />
                                      <button
                                        type="button"
                                        className="modify-button remove-button"
                                        onClick={() =>
                                          setOtherInfluentialOwnerCompanies(
                                            otherInfluentialOwnerCompanies.filter(
                                              (filterInfluential) => {
                                                return (
                                                  filterInfluential !==
                                                  influentialCompany
                                                );
                                              }
                                            )
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon
                                          className="icon"
                                          icon={faMinus}
                                        />
                                        <p>Ta bort</p>
                                      </button>
                                    </div>
                                  );
                                }
                              )
                            : ''}
                          <button
                            className="modify-button"
                            type="button"
                            onClick={() => AddOtherInfluentialCompany()}
                          >
                            <FontAwesomeIcon className="icon" icon={faPlus} />
                            <p>Lägg till bolag</p>
                          </button>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="form-section">
                    <div className="form-column">
                      <p className="medium-title">Verksamhet</p>
                      <FormTextArea
                        label="Beskriv detaljerat om din verksamhet. Exempel, vilka typer
                    av kunder ni jobbar med, privat/företag? Vilka leverantörer?"
                        rows={6}
                        value={detailedBusinessDescription}
                        onChange={(e) =>
                          setDetailedBusinessDescription(e.target.value)
                        }
                      />
                    </div>
                  </div>
                </ClassicForm>
              ) : formStep === 5 ? (
                <ClassicForm>
                  {formType === '0' ? (
                    <div className="form-section">
                      <div className="form-column">
                        <p className="medium-title">Syfte med lånet</p>
                        <FormTextArea
                          rows={6}
                          label="Vänligen förklara mer ingående vad lånet är avsett för. Uppge exempel på leverantör som skall betalas och/eller lån som skall re-finansieras."
                          value={detailedLoanPurpose}
                          onChange={(e) =>
                            setDetailedLoanPurpose(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="form-section">
                    <div className="form-column">
                      <p className="medium-title">Framtidsplaner</p>
                      <FormTextArea
                        rows={6}
                        label="Vilka är dina framtidsplaner när det gäller förväntad utveckling av exempelvis omsättning, intäkter, kostnader eller resultat?"
                        value={futurePlans}
                        onChange={(e) => setFuturePlans(e.target.value)}
                      />
                      <div className="form-row">
                        <div className="form-column">
                          <FormInput
                            label="Uppskattad omsättning om 3 månader"
                            value={estimatedRevenueThreeMonths}
                            onChange={(e) =>
                              setEstimatedRevenueThreeMonths(e.target.value)
                            }
                          />
                          <FormInput
                            label="Uppskattad omsättning om 6 månader"
                            value={estimatedRevenueSixMonths}
                            onChange={(e) =>
                              setEstimatedRevenueSixMonths(e.target.value)
                            }
                          />
                          <FormInput
                            label="Uppskattad omsättning om 12 månader"
                            value={estimatedRevenueTwelveMonths}
                            onChange={(e) =>
                              setEstimatedRevenueTwelveMonths(e.target.value)
                            }
                          />
                        </div>
                        <div className="form-column">
                          <FormInput
                            label="Uppskattat resultat om 3 månader"
                            value={estimatedResultThreeMonths}
                            onChange={(e) =>
                              setEstimatedResultThreeMonths(e.target.value)
                            }
                          />
                          <FormInput
                            label="Uppskattat resultat om 6 månader"
                            value={estimatedResultSixMonths}
                            onChange={(e) =>
                              setEstimatedResultSixMonths(e.target.value)
                            }
                          />
                          <FormInput
                            label="Uppskattat resultat om 12 månader"
                            value={estimatedResultTwelveMonths}
                            onChange={(e) =>
                              setEstimatedResultTwelveMonths(e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-section">
                    <div className="radio-select">
                      <p className="medium-title">Månadsomsättning</p>
                      <FormInput
                        label="Hur mycket omsätter företaget varje månad?"
                        value={revenueMonthly}
                        onChange={(e) => setRevenueMothly(e.target.value)}
                      />
                      <p className="normal-text">
                        Vänligen specificera vad som ingår i månadsomsättningen.
                        Den kan inkludera försäljning av produkter, tjänster
                        eller andra intäktskällor.
                      </p>
                      <div className="choice-row">
                        <RadioButton
                          text="Produkter"
                          active={sellingProduct}
                          onClick={() => setSellingProduct(!sellingProduct)}
                          checkBox
                        />
                        <RadioButton
                          text="Tjänster"
                          active={sellingService}
                          onClick={() => setSellingService(!sellingService)}
                          checkBox
                        />
                        <RadioButton
                          text="Annat"
                          active={otherRevenueSource}
                          onClick={() =>
                            setOtherRevenueSource(!otherRevenueSource)
                          }
                          checkBox
                        />
                      </div>
                    </div>
                    {sellingProduct ? (
                      <FormTextArea
                        rows={4}
                        label="Förklara produkter"
                        value={productDescription}
                        onChange={(e) => setProductDescription(e.target.value)}
                      />
                    ) : (
                      ''
                    )}
                    {sellingService ? (
                      <FormTextArea
                        rows={4}
                        label="Förklara tjänster"
                        value={serviceDescription}
                        onChange={(e) => setServiceDescription(e.target.value)}
                      />
                    ) : (
                      ''
                    )}
                    {otherRevenueSource ? (
                      <FormTextArea
                        rows={4}
                        label="Förklara annat"
                        value={otherRevenueSourceDescription}
                        onChange={(e) =>
                          setOtherRevenueSourceDescription(e.target.value)
                        }
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </ClassicForm>
              ) : formStep === 6 ? (
                <ClassicForm>
                  <div className="form-section">
                    <div className="radio-select">
                      <p className="small-title">
                        Ägnar du dig åt valutaväxling?
                      </p>
                      <div className="choice-row">
                        <RadioButton
                          text="Ja"
                          active={currencyExchange}
                          onClick={() => setCurrencyExchange(true)}
                        />
                        <RadioButton
                          text="Nej"
                          active={!currencyExchange}
                          onClick={() => setCurrencyExchange(false)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-section">
                    <div className="radio-select">
                      <p className="small-title">
                        Är du inblandad i omvandling till kryptovaluta?
                      </p>
                      <div className="choice-row">
                        <RadioButton
                          text="Ja"
                          active={cryptoCurrencyExchange}
                          onClick={() => setCryptoCurrencyExchange(true)}
                        />
                        <RadioButton
                          text="Nej"
                          active={!cryptoCurrencyExchange}
                          onClick={() => setCryptoCurrencyExchange(false)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-section">
                    <div className="radio-select">
                      <p className="small-title">
                        Använder företaget kontanter?
                      </p>
                      <div className="choice-row">
                        <RadioButton
                          text="Ja"
                          active={handlingCash}
                          onClick={() => setHandlingCash(true)}
                        />
                        <RadioButton
                          text="Nej"
                          active={!handlingCash}
                          onClick={() => setHandlingCash(false)}
                        />
                      </div>
                    </div>
                    {handlingCash ? (
                      <FormInput
                        label="Ange belopp per månad"
                        value={cashMonthly}
                        onChange={(e) => setCashMonthly(e.target.value)}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="form-section">
                    <div className="radio-select">
                      <p className="small-title">Insättningar</p>
                      <p className="normal-text">
                        {`Har du planer på att göra extra avbetalningar/insättningar
                    varje månad? Om "Ja", hur ofta kommer insättningar ske under
                    månaden?`}
                      </p>
                      <div className="choice-column">
                        <RadioButton
                          text="Nej"
                          active={nrOfExtraRepayments === 0}
                          onClick={() => setNrOfExtraRepayments(0)}
                        />
                        <RadioButton
                          text="1 - 2"
                          active={nrOfExtraRepayments === 1}
                          onClick={() => setNrOfExtraRepayments(1)}
                        />
                        <RadioButton
                          text="2 - 4"
                          active={nrOfExtraRepayments === 2}
                          onClick={() => setNrOfExtraRepayments(2)}
                        />
                        <RadioButton
                          text="5 - 8"
                          active={nrOfExtraRepayments === 3}
                          onClick={() => setNrOfExtraRepayments(3)}
                        />
                        <RadioButton
                          text="Mer än 8"
                          active={nrOfExtraRepayments === 4}
                          onClick={() => setNrOfExtraRepayments(4)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-section">
                    <div className="form-column">
                      <p className="small-title">Uttag</p>
                      <p className="normal-text">
                        Ange hur många uttag du planerar att göra i månaden.
                        Självklart kan du inte veta säkert så gör en
                        uppskattningvad det kan bli i genomsnitt per månad.
                        (Skriv 0 vid ansökan om ett företagslån)
                      </p>
                      <FormInput
                        label="Antal uttag"
                        value={nrOfWithdrawals}
                        onChange={(e) => setNrOfWithdrawals(e.target.value)}
                      />
                    </div>
                  </div>
                </ClassicForm>
              ) : formStep === 7 ? (
                <ClassicForm>
                  <div className="form-section">
                    <div className="form-column">
                      <p className="medium-title">Skicka in</p>
                      <p className="small-title">
                        Vem ska signera det här dokumentet? Du kan välja fler.
                      </p>
                      <div className="form-column">
                        {possibleSubjects.length > 0
                          ? possibleSubjects.map((subject, index) => {
                              return (
                                <div className="subject-row" key={subject.id}>
                                  <RadioButton
                                    text={subject.name}
                                    checkBox
                                    active={subject.selected}
                                    onClick={() => {
                                      const newArray = [...possibleSubjects];
                                      newArray[index].selected =
                                        !subject.selected;
                                      setPossibleSubjects(newArray);
                                    }}
                                  />
                                  {subject.selected ? (
                                    <>
                                      <FormInput
                                        label="Email"
                                        value={subject.email}
                                        onChange={(e) => {
                                          const newArray = [
                                            ...possibleSubjects,
                                          ];
                                          newArray[index].email =
                                            e.target.value;
                                          setPossibleSubjects(newArray);
                                        }}
                                      />
                                      <FormFileUpload
                                        label="Ladda upp pass"
                                        onChange={(e) => {
                                          const newArray = [
                                            ...possibleSubjects,
                                          ];
                                          if (e.target.files) {
                                            const file = e.target.files[0];
                                            newArray[index].idFile = file;
                                          } else {
                                            newArray[index].idFile = {};
                                          }
                                          setPossibleSubjects(newArray);
                                        }}
                                      />
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              );
                            })
                          : ''}
                      </div>
                    </div>
                  </div>
                </ClassicForm>
              ) : (
                ''
              )}
            </div>
            <div className="nav-footer">
              {formStep !== 1 ? (
                <XpektorButton
                  content="Föregående steg"
                  onClick={() => {
                    if (formStep > 1) setFormStep(formStep - 1);
                  }}
                />
              ) : (
                ''
              )}
              <div />

              <XpektorButton
                content={formStep < maxSteps ? 'Nästa steg' : 'Skicka in'}
                disabled={!CanGoNext()}
                onClick={() => {
                  if (formStep < maxSteps) {
                    setFormStep(formStep + 1);
                  } else SubmitKYCForm();
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
