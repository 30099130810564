import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import App from './App';
import Login from './Pages/Home/Login/Login';
import ResetPassword from './Pages/Home/ResetPassword/ResetPassword';
import AuthorizeFortnox from './Components/Authorize/AuthorizeFortnox';
import Landing from './Pages/Home/Landing';
import Finance from './Pages/Home/Finance';
import Advice from './Pages/Home/Advice';
import About from './Pages/Home/About';
import PrivacyPolicy from './Pages/Home/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from './Pages/Home/PrivacyPolicy/TermsAndConditions';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import AuthorizePresigned from './Components/Authorize/AuthorizePresigned';
import AuthorizeEnableBanking from './Components/Authorize/AuthorizeEnableBanking';
import AuthorizeVisma from './Components/Authorize/AuthorizeVisma';
import ExternalAuthorization from './Pages/ExternalAuthorization/ExternalAuthorization';
import Tutorials from './Pages/Home/Tutorials/Tutorials';
import Why from './Pages/Home/Why/Why';
import KYCForm from './Pages/Home/KYC/KYCForm';
import NotFound from './Pages/Home/NotFound/NotFound';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/">
          <Landing />
        </Route>
        <Route exact path="/finance">
          <Finance />
        </Route>
        <Route exact path="/advice">
          <Advice />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/createpassword">
          <ResetPassword />
        </Route>
        <Route exact path="/authorize">
          <AuthorizeFortnox />
        </Route>
        <Route exact path="/authorizeVisma">
          <AuthorizeVisma />
        </Route>
        <Route exact path="/presigned">
          <AuthorizePresigned />
        </Route>
        <Route exact path="/ebUpdate">
          <AuthorizeEnableBanking />
        </Route>
        <Route exact path="/authorizeBanking">
          <AuthorizeEnableBanking />
        </Route>
        <Route exact path="/authorization">
          <ExternalAuthorization />
        </Route>
        <Route exact path="/kyc-form">
          <KYCForm />
        </Route>
        <Route exact path="/privacypolicy">
          <PrivacyPolicy />
        </Route>
        <Route exact path="/termsandconditions">
          <TermsAndConditions />
        </Route>
        <Route exact path="/tutorials">
          <Tutorials />
        </Route>
        <Route exact path="/why">
          <Why />
        </Route>
        <Route exact path="/not-found">
          <NotFound />
        </Route>
        <Route>
          <App appTitle="Xpektor" />
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.unregister();
