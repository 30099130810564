/* eslint-disable react/prop-types */
import './DataPanel.scss';
import Moment from 'moment';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAnglesDown,
  faAnglesUp,
  faChevronRight,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { Tooltip } from 'react-tooltip';
import HoverableText from '../HoverableText/HoverableText';

export default function DataPanel({
  children,
  title,
  icon,
  iconText,
  background,
  scroll,
  givenRef,
  givenId,
  height,
}) {
  return (
    <div
      className={`datapanel-wrapper ${
        background && 'datapanel-wrapper-background'
      }`}
      ref={givenRef}
      id={givenId}
      style={height ? { maxHeight: height } : {}}
    >
      {title && (
        <div className="datapanel-title-container">
          <p>{title}</p>
          {icon && (
            <FontAwesomeIcon icon={icon} className="datapanel-title-icon" />
          )}
          {iconText && (
            <div
              className={`datapanel-title-icon-background grade-${iconText}`}
            >
              <p className="datapanel-title-icon-text">{iconText}</p>
            </div>
          )}
        </div>
      )}
      <div
        className={`datapanel-content-container ${
          scroll && 'scrollable-panel-content'
        }`}
      >
        {children}
      </div>
    </div>
  );
}

DataPanel.Subtitle = function Subtitle({ title, info, green, red }) {
  return (
    <HoverableText
      styleClass={`sub-title ${
        green ? 'datapanel-green' : red ? 'datapanel-red' : ''
      }`}
      text={title}
      info={info}
    />
  );
};

/** Used for displaying a labeled value in a module. */
DataPanel.ValueEntry = function ValueEntry(props) {
  const { label } = props;
  const { info } = props;
  const { value } = props;
  const { valueType } = props;

  let formattedValue = '';
  let formattedSign = '';

  if (valueType === 'currency') {
    formattedValue = Number(Number(value).toFixed(0))
      .toLocaleString()
      .replaceAll(',', ' ');
    formattedSign = 'kr';
  } else if (valueType === 'percentage') {
    formattedValue = Number(value)
      .toFixed(0)
      .toLocaleString()
      .replaceAll(',', ' ');
    formattedSign = '%';
  } else {
    formattedValue = value;
    formattedSign = valueType;
  }

  return (
    <div className="datapanel-entry-container">
      <div className="entry-title-container">
        <HoverableText styleClass="entry-text" text={label} info={info} />
        <div className="entry-value">
          <p className="value">{formattedValue}</p>
          <p className="value-sign">{formattedSign}</p>
        </div>
      </div>
    </div>
  );
};

DataPanel.TriggerEntry = function TriggerEntry({
  name,
  info,
  icon,
  requirement,
  tooltipId,
  expandedContent,
}) {
  const [toolTipContent, setToolTipContent] = useState('');
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    if (expandedContent) {
      setExpanded(!expanded);
    }
  };

  return (
    <div className="datapanel-entry-container">
      <button
        className={`entry-title-container ${
          expandedContent ? 'entry-expandable' : ''
        }`}
        type="button"
        onClick={() => toggleExpanded()}
        onKeyDown={() => toggleExpanded()}
      >
        {icon && <FontAwesomeIcon icon={icon} className="entry-icon warning" />}
        <p className="entry-text">{name}</p>
        {info && (
          <FontAwesomeIcon
            icon={faCircleQuestion}
            className="info-icon"
            data-tooltip-id={tooltipId}
            onMouseEnter={() => {
              setToolTipContent(info);
            }}
          />
        )}
        <div className="trigger-entry-end-container">
          {requirement && (
            <div
              className="datapanel-entry-tag"
              data-tooltip-id={tooltipId}
              onMouseEnter={() => {
                setToolTipContent(requirement);
              }}
            >
              <p>KRAV</p>
            </div>
          )}
          {expandedContent && (
            <FontAwesomeIcon
              className="entry-expand-icon"
              icon={expanded ? faChevronDown : faChevronRight}
            />
          )}
        </div>
      </button>
      {expanded && (
        <div className="entry-expanded-content">
          {expandedContent ?? <p>Finns ingenting här.</p>}
        </div>
      )}
      <Tooltip className="hover-info-tooltip" id={tooltipId} place="top">
        {toolTipContent}
      </Tooltip>
    </div>
  );
};

DataPanel.Button = function Button({ onClick, disabled, loading, text }) {
  return (
    <button
      className={`datapanel-normal-button ${disabled ? 'disabled-button' : ''}`}
      onClick={onClick}
      disabled={disabled || loading}
      type="button"
    >
      <p>{text}</p>
    </button>
  );
};

DataPanel.ExpandButton = function ExpandButton({
  expanded,
  onClick,
  text,
  loading,
}) {
  return (
    <button
      className="datapanel-expand-button"
      onClick={onClick}
      disabled={loading}
      type="button"
    >
      {loading ? (
        <div className="expand-loading-icon" />
      ) : (
        <>
          <p>{text ?? 'Expandera'}</p>
          <FontAwesomeIcon
            icon={expanded ? faAnglesUp : faAnglesDown}
            className="expand-icon"
          />
        </>
      )}
    </button>
  );
};

DataPanel.DoubleValueEntry = function DoubleValueEntry({
  title,
  subTitle,
  valueOne,
  valueTwo,
  valueOneName,
  valueTwoName,
}) {
  return (
    <div className="double-value-entry-container">
      <div className="double-value-entry-title-column">
        <p>{title}</p>
        {subTitle && <p className="datapanel-gray-text">{subTitle}</p>}
      </div>
      <div className="double-value-entry-column">
        <div className="double-value-entry-row">
          <p className="datapanel-gray-text">{valueOneName}</p>
          <p>{Moment(valueOne).format('YYYY-MM-DD')}</p>
        </div>
        {valueTwo && (
          <div className="double-value-entry-row">
            <p className="datapanel-gray-text">{valueTwoName}</p>
            <p>{Moment(valueTwo).format('YYYY-MM-DD')}</p>
          </div>
        )}
      </div>
    </div>
  );
};

DataPanel.Placeholder = function Placeholder({ content }) {
  return <div className="datapanel-placeholder-content">{content}</div>;
};
