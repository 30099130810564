import './HomeNav.scss';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faX } from '@fortawesome/free-solid-svg-icons';
import XpektorLogo from '../../Components/XpektorLogo/XpektorLogo';
import RoundedButton from '../../Components/RoundedButton/RoundedButton';

/* eslint-disable react/prop-types */
function NavButton({ text, target }) {
  return (
    <div className="main-nav-button">
      <a href={target}>{text}</a>
      <div className="underline" />
    </div>
  );
}

export default function HomeNav() {
  const history = useHistory();
  const [menuOpen, setMenuOpen] = useState(false);

  const openDemoLink = () => {
    window.open(
      'https://meetings-eu1.hubspot.com/jacob-liebermann?embed=true',
      '_blank',
      'noopener,noreferrer'
    );
  };

  return (
    <nav className={`xpektor-home-nav ${menuOpen ? 'home-nav-back-fill' : ''}`}>
      <div className="top-row">
        <XpektorLogo darkMode />
        <div className="main-nav">
          <NavButton text="Kreditgivare" target="/finance" />
          <NavButton text="Rådgivare" target="/advice" />
          <NavButton text="Om oss" target="/about" />
        </div>
        <div className="action-nav">
          <RoundedButton text="Boka demo" fillCyan onClick={openDemoLink} />
          <RoundedButton
            text="Logga in"
            onClick={() => history.push('/login')}
          />
        </div>
        <FontAwesomeIcon
          className="mobile-icon"
          icon={menuOpen ? faX : faBars}
          onClick={() => setMenuOpen(!menuOpen)}
        />
      </div>
      {menuOpen ? (
        <div className="mobile-menu">
          <a href="/finance">Kreditgivare</a>
          <a href="/advice">Rådgivare</a>
          <a href="/about">Om oss</a>
          <a href="/login" className="login-button">
            Logga in
          </a>
        </div>
      ) : (
        ''
      )}
    </nav>
  );
}
