import './Home.scss';
import HomeNav from './HomeNav';
import Footer from './Components/Footer/Footer';
import RoundedButton from '../../Components/RoundedButton/RoundedButton';
import HelpOfferSection from './Components/HelpOfferSection';
import SpiderWebSection from './Components/SpiderWebSection';
import BannerSection from './Components/BannerSection';
import ProcessCard from './Components/ProcessCard';
import LogoSection from './Components/LogoSection';
import Background, { BackgroundElement } from './Components/Background';
import { OpenDemoLink } from '../../Components/Utilities/HelperFunctions';

// Media
import SwirlyLines1 from '../../Images/Home/swirly_lines_1.png';
import SwirlyLines2 from '../../Images/Home/swirly_lines_2.png';
import SwarmWave from '../../Images/Home/swarm_wave.png';
// import ParticleWaveClip from '../../Videos/blue_partcles_futuristic.mov';
import HelpOffer1 from '../../Images/Home/help_offer_1.png';
import ProcessImage1 from '../../Images/Home/process_card_1.png';
import ProcessImage2 from '../../Images/Home/process_card_2.png';
import ProcessImage3 from '../../Images/Home/process_card_3.png';
import FinanceWeb from '../../Images/Home/xpektor_web_finance.png';

export default function Landing() {
  return (
    <div className="xpektor-home">
      <div className="landing-intro">
        <HomeNav />
        <div className="section intro-section">
          <h1>Bedöm risker och möjligheter i realtid</h1>
          <p className="section-text">
            Xpektor är en unik mjukvara för kreditmarknaden som bedömer dina
            kunders riskprofil, hittar affärsmöjligheter och levererar kompletta
            beslutsunderlag. Automatiskt och i realtid.
          </p>
          <RoundedButton
            text="Kom igång direkt"
            fillCyan
            onClick={OpenDemoLink}
          />
        </div>
      </div>
      <Background color1="#000B4F">
        <div className="section-with-element">
          <div className="section landing-2">
            <p className="section-title">MINSKA DINA MANUELLA PROCESSER</p>
            <h2>Kreditbedömning på en ny nivå</h2>
            <p className="section-text">
              Med Xpektor får du som kreditgivare tillgång till dina kunders
              bokföring, kontotransaktioner, skattekonto, myndighetsinformation
              och mycket mer - allt i realtid. Xpektor är verktyget som
              underlättar kreditbeslut, identifierar affärsmöjligheter och
              minskar risken för bedrägerier och penningtvätt.
            </p>
          </div>
          <BackgroundElement img={SwirlyLines1} yPos={-200} />
        </div>
        {/* <div className="section video-section">
          <video autoPlay muted loop>
            <source src={ParticleWaveClip} />
          </video>
        </div> */}
        <div className="section-with-element">
          <HelpOfferSection
            image={HelpOffer1}
            title="Xpektor hjälper dig fatta trygga, säkra och snabba kreditbeslut"
            points={[
              'Snabbare handläggningstider',
              'Ökad lånevolym',
              'Fler affärsmöjligheter',
              'Robustare kreditbedömningar och sänkt kreditrisk',
              'Identifiering av potentiella bedrägerier och penningtvätt',
            ]}
          />
          <BackgroundElement img={SwirlyLines2} yPos={400} />
        </div>
      </Background>
      <Background color1="#000B4F" color2="#010024">
        <div className="section-with-element">
          <SpiderWebSection
            title="Hur fungerar Xpektor?"
            text="Med Xpektor får du som kreditgivare tillgång till dina kunders bokföring, kontotransaktioner, skattekonto, myndighetsinformation och mycket mer. Kunden ansluter enkelt sitt företag via en aktiveringslänk. Därefter analyserar Xpektor företagets återbetalningsförmåga, risker för bedrägerier och penningtvätt samt hjälper till att hitta affärsmöjligheter. Automatiskt och i realtid."
            image={FinanceWeb}
          />
          <BackgroundElement img={SwarmWave} yPos={700} />
        </div>
        <div className="section home-4">
          <p className="section-title">PROCESS</p>
          <h2>Trött på komplicerade processer?</h2>
          <div className="row-content">
            <ProcessCard
              image={ProcessImage1}
              title="Anslut"
              text="Logga in och använd Xpektor direkt via vår webbplats, eller integrera lösningen smidigt med dina befintliga system. Med vår plug-in-lösning får du all funktionalitet du behöver, utan krångel, för en smidig och snabb installation."
            />
            <ProcessCard
              image={ProcessImage2}
              title="Uteslut"
              text="Få omedelbara uppdateringar när något viktigt händer. Med realtidsnotiser är du alltid ett steg före och kan agera snabbt och effektivt för att optimera dina processer och beslutstider."
            />
            <ProcessCard
              image={ProcessImage3}
              title="Beslut"
              text="Se helheten och få en tydlig översikt med vårt score och använd Xpektors analys för att fatta välgrundade beslut."
            />
          </div>
        </div>
        <div className="section-with-element">
          <LogoSection />
          <BackgroundElement img={SwirlyLines2} yPos={-300} />
        </div>
      </Background>
      <Background end>
        <BannerSection />
        <Footer darkMode />
      </Background>
    </div>
  );
}
