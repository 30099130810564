import './RadioButton.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

/* eslint-disable react/prop-types */
export default function RadioButton({ text, active, onClick, checkBox }) {
  return (
    <div className={`radio-button ${checkBox ? 'checkbox-button' : ''}`}>
      <button className="outer-circle" type="button" onClick={onClick}>
        {active ? (
          checkBox ? (
            <FontAwesomeIcon className="selected-icon" icon={faCheck} />
          ) : (
            <div className="selected-icon" />
          )
        ) : (
          ''
        )}
      </button>
      <p>{text}</p>
    </div>
  );
}
