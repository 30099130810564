import './KYC.scss';
// import { useState } from 'react';

/* eslint-disable react/prop-types */
/* function KYCProcessEntry({ entry }) {
  return (
    <div className="kyc-entry">
      <p className="client-name">{entry.client}</p>
      <div className="entry-content">
        <div className="entry-column">
          <p className="data-title">Typ:</p>
          <p>{entry.type}</p>
        </div>
        <div className="entry-column">
          <p className="data-title">Skapad:</p>
          <p>{entry.dateCreated}</p>
        </div>
        <div className="entry-column">
          <p className="data-title">Signerad av klient:</p>
          <p className={entry.signedByClient ? 'ok' : 'error'}>
            {entry.signedByClient ? 'Ja' : 'Nej'}
          </p>
        </div>
        <div className="entry-column">
          <p className="data-title">Datum signerat:</p>
          <p>{entry.dateSigned ?? 'N/A'}</p>
        </div>
        {entry.signedByClient ? <button type="button">Granska</button> : ''}
      </div>
    </div>
  );
} */

export default function KYC() {
  // const [kycEntries, setKycEntries] = useState([]);

  /* const kycTestEntries = [
    {
      id: 0,
      client: 'Demobolaget AB',
      type: 'Lån',
      signedByClient: false,
      dateCreated: '01-01-2025',
      dateSigned: null,
    },
    {
      id: 1,
      client: 'Demobolaget AB 1',
      type: 'Factoring',
      signedByClient: true,
      dateCreated: '01-01-2025',
      dateSigned: '01-01-2025',
    },
    {
      id: 2,
      client: 'Demobolaget AB 2',
      type: 'Lån',
      signedByClient: true,
      dateCreated: '01-01-2025',
      dateSigned: '01-01-2025',
    },
    {
      id: 3,
      client: 'Demobolaget AB 3',
      type: 'Lån',
      signedByClient: true,
      dateCreated: '01-01-2025',
      dateSigned: '01-01-2025',
    },
  ]; */

  return (
    <div className="kyc-wrapper">
      <div className="kyc-header">
        <h1>KYC</h1>
      </div>
      <div className="kyc-content">
        <p className="placeholder-text">Modulen är under uppbyggnad.</p>
        {/* <div className="entries">
          <div className="entry-partition">
            <h2>Slutförda</h2>
            {kycTestEntries
              .filter((entry) => {
                return entry.signedByClient === true;
              })
              .map((entry) => {
                return <KYCProcessEntry entry={entry} key={entry.id} />;
              })}
          </div>
          <div className="entry-partition">
            <h2>Väntar på klient</h2>
            {kycTestEntries
              .filter((entry) => {
                return entry.signedByClient === false;
              })
              .map((entry) => {
                return <KYCProcessEntry entry={entry} key={entry.id} />;
              })}
          </div>
        </div> */}
      </div>
    </div>
  );
}
