/* eslint-disable react/prop-types */
import { useHistory } from 'react-router-dom';
import './SpiderWebSection.scss';
import RoundedButton from '../../../Components/RoundedButton/RoundedButton';

export default function SpiderWebSection({ title, text, image }) {
  const history = useHistory();

  return (
    <div className="section spider-web-wrapper">
      <p className="section-title">EN MODERN SAAS-LÖSNING</p>
      <h2>{title}</h2>
      <p className="section-text">{text}</p>
      <RoundedButton
        text="Läs mer"
        fillCyan
        onClick={() => history.push('/why')}
      />
      <img src={image} alt="Xpektor web" />
    </div>
  );
}
